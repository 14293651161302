import Vue from "vue";

const getMaxRow = Vue.mixin({
  methods: {
    getMaxRow(payload, key) {
      if (payload) {
        const maxId = Math.max.apply(
          Math,
          payload.map(function(o) {
            return o.id;
          })
        );

        return payload.find(a => a.id === maxId);
      } else {
        return payload;
      }
    }
  }
});

export default getMaxRow;
