var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-loading',{model:{value:(_vm.ajaxloading.gettingTicket),callback:function ($$v) {_vm.$set(_vm.ajaxloading, "gettingTicket", $$v)},expression:"ajaxloading.gettingTicket"}}),_c('button',{staticClass:"button is-primary is-rounded is-small",on:{"click":function($event){return _vm.$router.push({ name: 'tickets-index' })}}},[_vm._v(" Terug naar tickets ")]),_c('br'),_c('br'),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._v("Ticket aangemaakt door:")]),(_vm.ticket.user)?_c('div',{staticClass:"column"},[_c('a',{on:{"click":function($event){return _vm.$router.push({ name: 'eddi', params: { eddi: _vm.ticket.user.id } })}}},[_vm._v(_vm._s(_vm.ticket.user.name))])]):_vm._e()]),(_vm.ticket.dossier && _vm.ticket.dossier.id)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._v("Ticket voor dossier:")]),_c('div',{staticClass:"column"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
            name: 'dossier',
            params: { dossier: _vm.ticket.dossier.slug }
          })}}},[_vm._v(" "+_vm._s(_vm.ticket.dossier.id + "-" + _vm.ticket.dossier.dossier_naam))])])]):_vm._e(),_c('br'),(_vm.ticket.dossier && _vm.ticket.dossier.id)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._v("Status ticket:")]),_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.ticket.status)+" ")])]):_vm._e(),_c('br'),(_vm.reageren === false)?_c('b-button',{staticClass:"is-primary",attrs:{"icon-left":"reply"},on:{"click":function($event){_vm.reageren = true}}},[_vm._v(" Reageren ")]):_c('b-button',{staticClass:"is-primary",attrs:{"icon-left":"times"},on:{"click":function($event){_vm.reageren = false}}},[_vm._v(" Annuleren ")]),(_vm.ticket.status === 'actief')?_c('b-button',{staticClass:"is-warning is-pulled-right",attrs:{"icon-left":"archive"},on:{"click":function($event){return _vm.archiveTicket()}}},[_vm._v(" Ticket Archiveren ")]):_vm._e(),(_vm.ticket.status !== 'actief')?_c('b-button',{staticClass:"is-warning is-pulled-right",attrs:{"icon-left":"archive"},on:{"click":function($event){return _vm.unArchiveTicket()}}},[_vm._v(" Ticket actief maken ")]):_vm._e(),_c('br'),_c('br'),(_vm.reageren === false)?_c('span',[_vm._l((_vm.comments),function(comment){return _c('div',{key:comment.id,staticClass:"box"},[_c('article',{staticClass:"media"},[_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image is-64x64"},[(comment.user.picture)?_c('img',{attrs:{"src":_vm.env.VUE_APP_API_URL +
                  '/web/get-ticket-picture/' +
                  comment.user.picture}}):_c('img',{attrs:{"src":require("@/assets/128x128.png")}})])]),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[_c('p',[(comment.user.rol === 'eddi')?_c('strong',[_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                      name: 'eddi',
                      params: { eddi: comment.user.id }
                    })}}},[_vm._v(_vm._s(_vm.hoofdletter(comment.user.name)))])]):_c('span',[_c('strong',[_vm._v(_vm._s(_vm.hoofdletter(comment.user.name)))]),(comment.user.rol === 'vizier')?_c('span',[_c('small',{staticClass:"vizier-rol"},[_vm._v(" Vizier")])]):_vm._e(),(comment.user.rol === 'admin')?_c('span',[_c('small',{staticClass:"vizier-rol"},[_vm._v(" Admin")])]):_vm._e()]),_vm._v(" op "),_c('small',[_vm._v(_vm._s(_vm.formatDatabaseDate(comment.created_at))+" om "+_vm._s(_vm.formatDatabaseTime(comment.created_at)))]),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(comment.ticket)}})])])])])])}),_c('div',{staticClass:"box"},[_c('article',{staticClass:"media"},[_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image is-64x64"},[(_vm.ticket.user && _vm.ticket.user.picture)?_c('img',{attrs:{"src":_vm.env.VUE_APP_API_URL +
                  '/web/get-ticket-picture/' +
                  _vm.ticket.user.picture}}):_c('img',{attrs:{"src":require("@/assets/128x128.png")}})])]),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[_c('p',[(_vm.ticket.user)?_c('span',[(_vm.ticket.user.rol === 'eddi')?_c('strong',[_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                        name: 'eddi',
                        params: { eddi: _vm.ticket.user.id }
                      })}}},[_vm._v(_vm._s(_vm.hoofdletter(_vm.ticket.user.name)))])]):_c('span',[_c('strong',[_vm._v(_vm._s(_vm.hoofdletter(_vm.ticket.user.name)))]),(_vm.ticket.user.rol === 'vizier')?_c('span',[_c('small',{staticClass:"vizier-rol"},[_vm._v(" Vizier")])]):_vm._e(),(_vm.ticket.user.rol === 'admin')?_c('span',[_c('small',{staticClass:"vizier-rol"},[_vm._v(" Admin")])]):_vm._e()])]):_vm._e(),_vm._v(" op "),_c('small',[_vm._v(_vm._s(_vm.formatDatabaseDate(_vm.ticket.created_at))+" om "+_vm._s(_vm.formatDatabaseTime(_vm.ticket.created_at)))]),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.ticket.ticket)}})])])])])]),_c('br')],2):_c('span',[_c('formEditor',{attrs:{"modelKey":"comment","title":" ","model":_vm.form}}),_c('br'),_c('button',{staticClass:"button is-primary is-rounded",class:{ 'is-loading': _vm.ajaxloading.createComment },on:{"click":function($event){return _vm.createComment()}}},[_vm._v(" Reageren ")]),_c('button',{staticClass:"button is-rounded",on:{"click":function($event){_vm.reageren = false}}},[_vm._v(" Annuleren ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }