<style scoped>
.column {
  padding-top: 0px;
  padding-bottom: 0px;
}

.no-padding {
  padding-top: 0px;
  padding-bottom: 0px;
}

.reply {
  margin-left: 50px;
}

.table-icons {
  float: right;
}

.vizier-rol {
  color: red;
}
</style>

<template>
  <div>
    <div>
      <button
        class="button is-primary is-rounded is-small"
        @click="$router.push({ name: 'dossiers-index' })"
      >
        Terug naar dossiers
      </button>
    </div>
    <br />

    <b-tabs v-model="selectedTab" expanded>
      <b-loading v-model="ajaxloading.gettingdossier" />
      <b-tab-item label="Stamgegevens">
        <br />
        <div class="columns is-mobile">
          <div class="column">
            <span class="title is-5">Dossier: {{ dossier.slug }}</span> &nbsp;
            <br />
            <span class="subtitle is-5"
              >Coach:
              <span v-if="dossier.user">{{ dossier.user.name }}</span></span
            >
          </div>
          <div class="column">
            <!-- <b-icon
              class="pointer is-pulled-right"
              icon="trash"
              @click.native="deleteProduct()"
            /> -->
            <div class="block table-icons">
              <b-tooltip label="Dossier foto aanpassen" position="is-top">
                <span class="pointer" @click="dossierFotoAanpassen()">
                  <b-icon class="pointer" icon="camera" />
                </span>
              </b-tooltip>
              <b-tooltip label="Dossier aanpassen" position="is-top">
                <span class="pointer" @click="dossierAanpassen()">
                  <b-icon class="pointer" icon="edit" />
                </span>
              </b-tooltip>
            </div>
          </div>
        </div>

        <span v-if="dossier">
          <div class="title is-5 has-text-centered">Gegevens melder</div>

          <showModel
            :model="dossier"
            modelKey="datum_melding"
            title="Datum melding"
            type="date"
          />
          <showModel
            v-if="dossier.bron !== 'anders'"
            :model="dossier"
            modelKey="bron"
            title="Bron"
          />
          <showModel
            v-else
            :model="dossier"
            title="Bron"
            modelKey="bron_anders"
          />
          <showModel
            :model="dossier"
            modelKey="gemeente_melder"
            title="Gemeente melder"
          />
          <showModel
            :model="dossier"
            modelKey="meldingvoor"
            title="Type melding"
          />

          <br />

          <showModel :model="dossier" modelKey="naam" />
          <showModel :model="dossier" modelKey="telefoonnummer" />
          <showModel :model="dossier" modelKey="email" />
          <showModel :model="dossier" modelKey="leeftijd" />

          <div class="title is-5 has-text-centered">Gegevens Incident</div>
          <showModel
            :model="dossier"
            modelKey="type_incident"
            title="Type Incident"
          />

          <showModel
            v-if="dossier.grondslag !== 'anders'"
            :model="dossier"
            modelKey="grondslag"
          />
          <showModel v-else :model="dossier" modelKey="grondslag_anders" />

          <showModel
            v-if="dossier.aard !== 'anders'"
            :model="dossier"
            modelKey="aard"
          />
          <showModel v-else :model="dossier" modelKey="aard_anders" />

          <showModel
            :model="dossier"
            modelKey="omschrijving_incident"
            title="Omschrijving Incident"
          />

          <br />

          <showModel
            :model="dossier"
            modelKey="datum_incident"
            title="Datum incident"
            type="date"
          />

          <showModel :model="dossier" modelKey="postcode" />
          <showModel :model="dossier" modelKey="plaats" />
          <showModel :model="dossier" modelKey="terrein" />
          <showModel :model="dossier" modelKey="gemeente" />

          <br />
          <showModel
            :model="dossier"
            modelKey="dader_informatie"
            title="Daderinformatie"
          />
          <showModel
            :model="dossier"
            modelKey="gewenste_maatregelen"
            title="Gewenste maatregelen"
          />
          <showModel
            :model="dossier"
            modelKey="ervaring"
            title="Ervaring incident"
          />
          <br />
          <showModel :model="dossier" modelKey="status" />
        </span>
      </b-tab-item>

      <b-tab-item label="Rapportages">
        <b-button
          v-if="rapporteren === false"
          class="is-primary"
          icon-left="reply"
          @click="rapporteren = true"
        >
          Rapporteren
        </b-button>

        <b-button
          v-else
          class="is-primary"
          icon-left="times"
          @click="rapporteren = false"
        >
          Annuleren
        </b-button>

        <br />
        <br />

        <span v-if="rapporteren">
          <formEditor
            modelKey="rapportage"
            title="Rapportage toevoegen"
            :model="form"
          />
          <br />
          <button
            :class="{ 'is-loading': ajaxloading.createRapportage }"
            class="button is-primary is-rounded"
            @click="createRapportage()"
          >
            Rapportage plaatsen</button
          >&nbsp;
          <button class="button is-rounded" @click="rapporteren = false">
            Annuleren
          </button>
        </span>

        <span
          v-if="
            dossier.rapportages && dossier.rapportages.length && !rapporteren
          "
        >
          <div
            class="box"
            v-for="rapportage of dossier.rapportages.slice().reverse()"
            :key="rapportage.id"
          >
            <article class="media">
              <figure class="media-left">
                <p class="image is-64x64">
                  <img
                    v-if="rapportage.user.picture"
                    :src="
                      env.VUE_APP_API_URL +
                      '/web/get-ticket-picture/' +
                      rapportage.user.picture
                    "
                  />
                  <img v-else src="@/assets/128x128.png" />
                </p>
              </figure>
              <div class="media-content">
                <div class="content">
                  <p>
                    <strong>{{ hoofdletter(rapportage.user.name) }}</strong
                    ><span v-if="rapportage.user.rol === 'vizier'"
                      ><small class="vizier-rol"> Vizier</small></span
                    >
                    <span v-if="rapportage.user.rol === 'admin'"
                      ><small class="vizier-rol"> Admin</small></span
                    >
                    op
                    <small
                      >{{ formatDatabaseDate(rapportage.created_at) }} om
                      {{ formatDatabaseTime(rapportage.created_at) }}</small
                    >
                    <br />
                    <span v-html="rapportage.rapportage"></span>
                  </p>
                </div>
                <nav class="level is-mobile">
                  <div class="level-left">
                    <a
                      class="level-item"
                      @click="setSelectedRapportage(rapportage)"
                    >
                      <b-icon icon="reply"></b-icon>Reageren
                    </a>
                  </div>
                </nav>
              </div>
            </article>
            <br />
            <div class="reply">
              <article
                class="media"
                v-for="comment of rapportage.comments"
                :key="comment.id"
              >
                <figure class="media-left">
                  <p class="image is-64x64">
                    <img
                      v-if="comment.user.picture"
                      :src="
                        env.VUE_APP_API_URL +
                        '/web/get-ticket-picture/' +
                        comment.user.picture
                      "
                    />
                    <img v-else src="@/assets/128x128.png" />
                  </p>
                </figure>
                <div class="media-content">
                  <div class="content">
                    <p>
                      <strong>{{ hoofdletter(comment.user.name) }}</strong
                      ><span v-if="comment.user.rol === 'vizier'"
                        ><small class="vizier-rol"> Vizier</small></span
                      >
                      <span v-if="comment.user.rol === 'admin'"
                        ><small class="vizier-rol"> Admin</small></span
                      >
                      op
                      <small
                        >{{ formatDatabaseDate(comment.created_at) }} om
                        {{ formatDatabaseTime(comment.created_at) }}</small
                      >
                      <br />
                      <span v-html="comment.rapportage"></span>
                    </p>
                  </div>
                  <nav class="level is-mobile">
                    <div class="level-left">
                      <a
                        class="level-item"
                        @click="setSelectedRapportage(rapportage)"
                      >
                        <b-icon icon="reply"></b-icon>Reageren
                      </a>
                    </div>
                  </nav>
                </div>
              </article>
              <span v-if="selectedRapportage === rapportage.id">
                <formEditor modelKey="rapportage" title=" " :model="form" />
                <br />
                <button
                  @click="addComment()"
                  :class="{ 'is-loading': ajaxloading.createComment }"
                  class="button is-primary is-rounded"
                >
                  Reageren</button
                >&nbsp;<button
                  class="button is-rounded"
                  @click="selectedRapportage = ''"
                >
                  Annuleren
                </button>
              </span>
            </div>
          </div>
        </span>
        <br />
      </b-tab-item>

      <b-tab-item label="Tickets">
        <tickets v-if="dossier.tickets" :dossier="dossier" />
      </b-tab-item>

      <b-tab-item label="Logboek">
        <logs />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import logs from "./DossierLogs.vue";
import tickets from "./DossierTickets.vue";
export default {
  components: {
    logs,
    tickets,
  },
  props: [],
  data() {
    return {
      selectedTab: 0,
      form: {},
      selectedRapportage: "",
      rapporteren: false,
      env: process.env,
    };
  },
  computed: {
    dossier() {
      return this.$store.getters["dossiers/dossier"];
    },
  },
  methods: {
    dossierAanpassen() {
      this.$router.push({
        name: "dossier-aanpassen",
        params: { dossier: this.dossier.slug },
      });
    },
    dossierFotoAanpassen() {
      this.$router.push({
        name: "dossier-foto-aanpassen",
        params: { dossier: this.dossier.slug },
      });
    },
    createRapportage() {
      this.$store.dispatch("dossiers/createRapportage", this.form).then(() => {
        this.form = {};
        this.rapporteren = false;
      });
    },
    addComment() {
      this.form.selectedRapportage = this.selectedRapportage;
      this.$store.dispatch("dossiers/createComment", this.form).then(() => {
        this.form = {};
        this.selectedRapportage = "";
      });
    },
    setSelectedRapportage(rapportage) {
      this.selectedRapportage = rapportage.id;
    },
  },
  mounted() {
    this.$store.dispatch("dossiers/getDossier", this.$route.params.dossier);
  },
};
</script>
