var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{staticClass:"navbar is-primary"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"navbar-brand"},[_c('a',{staticClass:"navbar-burger",attrs:{"role":"button","aria-label":"menu","aria-expanded":"true"},on:{"click":function($event){return _vm.toggleMobile()}}},[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}})])]),_c('div',{class:['navbar-menu', { 'is-active': _vm.showMobile }]},[_c('div',{staticClass:"navbar-start"},[_c('div',{staticClass:"navbar-item"},[_vm._v("Coaches | Vizieroost")]),_c('singleLink',{attrs:{"link":{ naam: 'Dashboard', routerLink: 'app', exact: true }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}),_c('singleLink',{attrs:{"link":{
              naam: 'Dossiers',
              routerLink: 'dossiers-index',
            }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}),_c('singleLink',{attrs:{"link":{
              naam: 'Tickets',
              routerLink: 'tickets-index',
            }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}),(_vm.user.rol !== 'eddi')?_c('singleLink',{attrs:{"link":{
              naam: `Coaches`,
              routerLink: 'eddi-index',
            }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}):_vm._e(),(_vm.user.rol !== 'eddi')?_c('singleLink',{attrs:{"link":{
              naam: `Rapportages`,
              routerLink: 'rapportages-index',
            }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}):_vm._e(),(_vm.user.rol !== 'eddi')?_c('singleLink',{attrs:{"link":{
              naam: `Logboek`,
              routerLink: 'logboek-index',
            }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}):_vm._e()],1),_c('div',{staticClass:"navbar-end",on:{"click":function($event){return _vm.closeMobile()}}},[(_vm.user.rol === 'admin' || _vm.user.rol === 'superuser')?_c('singleLink',{attrs:{"link":{
              naam: `Gebruikers`,
              routerLink: 'gebruikers-index',
            }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}):_vm._e(),_c('dropDownLink',{attrs:{"showMobileParent":_vm.showMobile,"link":{
              naam: _vm.user.name,
              routerLink: 'instellingen',
              exact: true,
              children: [
                {
                  naam: 'Instellingen',
                  routerLink: 'instellingen',
                  exact: true,
                },
                {
                  naam: 'Foto aanpassen',
                  routerLink: 'instellingen-foto-aanpassen',
                },
                {
                  naam: 'Apparaten',
                  routerLink: 'instellingen-apparaten',
                },
                {
                  divider: true,
                },
                {
                  naam: 'Logout',
                  emit: 'logout',
                },
              ],
            }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()},"logout":function($event){return _vm.logout()}}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }