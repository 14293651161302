<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}
</style>

<template>
  <div>
    <div class="users-title">Logboek</div>

    <br />
    <br />
    <div>
      <b-tabs>
        <b-loading v-model="ajaxloading.getloginlogs" />
        <b-loading v-model="ajaxloading.getactionlogs" />

        <b-tab-item label="Acties Logs">
          <b-table
            :data="actieLogs"
            :bordered="true"
            :striped="true"
            :paginated="true"
            :per-page="50"
            :pagination-simple="true"
            :pagination-position="'top'"
            :pagination-rounded="true"
            :loading="false"
            :focusable="false"
            :mobile-cards="false"
            :narrowed="true"
            detailed
          >
            <b-table-column
              field="id"
              label="ID"
              width="40"
              numeric
              v-slot="props"
            >
              {{ props.row.id }}
            </b-table-column>

            <b-table-column label="Gebruiker" v-slot="props">
              {{ getUserName(props.row.user_id, props.row.ip) }}
            </b-table-column>

            <b-table-column label="Timestamp" v-slot="props">
              {{ formatDatePickerDateAndTime(props.row.datum) }}
            </b-table-column>
            <b-table-column label="Actie" v-slot="props">
              {{ props.row.actie }}
            </b-table-column>
            <b-table-column label="Model" v-slot="props">
              {{ props.row.model }}
            </b-table-column>
            <b-table-column label="ModelID" v-slot="props">
              {{ props.row.modelId }}
            </b-table-column>
            <template slot="detail" slot-scope="props">
              <article class="media">
                <div class="media-content">
                  <div class="content">
                    <span v-if="props.row.actie === 'CREATE'">
                      <ul>
                        <li
                          v-for="detail of props.row.log_details"
                          :key="detail.id"
                        >
                          {{ detail.modelKey }} => "{{ detail.new }}"
                        </li>
                      </ul>
                    </span>
                    <span v-if="props.row.actie === 'UPDATE'">
                      <ul>
                        <li
                          v-for="detail of props.row.log_details"
                          :key="detail.id"
                        >
                          {{ detail.modelKey }} =>
                          <span
                            style="text-decoration: line-through"
                            v-if="detail.old"
                            >"{{ detail.old }}"</span
                          >
                          "{{ detail.new }}"
                        </li>
                      </ul>
                    </span>
                    <p
                      v-if="
                        props.row.actie === 'SHOW' ||
                        props.row.actie === 'DELETE'
                      "
                    >
                      Geen details
                    </p>
                  </div>
                </div>
              </article>
            </template>
          </b-table>
        </b-tab-item>
        <!-- <b-tab-item label="Inzage Logs">
          <b-table
            :data="inzageLogs"
            :bordered="true"
            :striped="true"
            :loading="false"
            :paginated="true"
            :per-page="50"
            :pagination-simple="true"
            :pagination-position="'top'"
            :pagination-rounded="true"
            :focusable="false"
            :mobile-cards="false"
            :narrowed="true"
            detailed
          >
            <b-table-column
              field="id"
              label="ID"
              width="40"
              numeric
              v-slot="props"
            >
              {{ props.row.id }}
            </b-table-column>

            <b-table-column label="Gebruiker" v-slot="props">
              {{ getUserName(props.row.user_id, props.row.ip) }}
            </b-table-column>
            <b-table-column label="IP" v-slot="props">
              {{ props.row.ip }}
            </b-table-column>
            <b-table-column label="Timestamp" v-slot="props">
              {{ formatDatePickerDateAndTime(props.row.datum) }}
            </b-table-column>
            <b-table-column label="Actie" v-slot="props">
              {{ props.row.actie }}
            </b-table-column>
            <b-table-column label="Model" v-slot="props">
              {{ props.row.model }}
            </b-table-column>
            <b-table-column label="ModelID" v-slot="props">
              {{ props.row.modelId }}
            </b-table-column>
            <template slot="detail" slot-scope="props">
              <article class="media">
                <div class="media-content">
                  <div class="content">
                    <span v-if="props.row.actie === 'CREATE'">
                      <ul>
                        <li
                          v-for="detail of props.row.log_details"
                          :key="detail.id"
                        >
                          {{ detail.modelKey }} => "{{ detail.new }}"
                        </li>
                      </ul>
                    </span>
                    <span v-if="props.row.actie === 'UPDATE'">
                      <ul>
                        <li
                          v-for="detail of props.row.log_details"
                          :key="detail.id"
                        >
                          {{ detail.modelKey }} =>
                          <span
                            style="text-decoration: line-through;"
                            v-if="detail.old"
                            >"{{ detail.old }}"</span
                          >
                          "{{ detail.new }}"
                        </li>
                      </ul>
                    </span>
                    <p
                      v-if="
                        props.row.actie === 'SHOW' ||
                          props.row.actie === 'DELETE'
                      "
                    >
                      Geen details
                    </p>
                  </div>
                </div>
              </article>
            </template>
          </b-table>
        </b-tab-item> -->
        <b-tab-item label="Login Logs">
          <b-table
            :data="loginLogs"
            :bordered="true"
            :striped="true"
            :loading="false"
            :paginated="true"
            :per-page="50"
            :pagination-simple="true"
            :pagination-position="'top'"
            :pagination-rounded="true"
            :focusable="false"
            :mobile-cards="false"
            narrowed
          >
            <b-table-column
              field="id"
              label="ID"
              width="40"
              numeric
              v-slot="props"
            >
              {{ props.row.id }}
            </b-table-column>
            <b-table-column label="Gebruiker" v-slot="props">
              {{ getUserName(props.row.user_id, props.row.ip) }}
            </b-table-column>

            <b-table-column label="Timestamp" v-slot="props">
              {{ formatDatePickerDateAndTime(props.row.datum) }}
            </b-table-column>
          </b-table>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {
    loginLogs() {
      return this.$store.getters["logboek/loginLogs"];
    },
    actieLogs() {
      return this.$store.getters["logboek/actieLogs"];
    },
    inzageLogs() {
      return this.$store.getters["logboek/inzageLogs"];
    },
    gebruikers() {
      return this.$store.getters["logboek/users"];
    },
  },
  methods: {
    getUserName(user_id, ip) {
      if (user_id === null || user_id === 0) {
        return "SYSTEEM";
      }

      const user = this.gebruikers.find((a) => a.id === user_id);

      if (user && user.rol === "superuser") {
        return "SYSTEEM|SUPERUSER";
      }
      // if (user.rol === "superuser") {
      // }

      if (user) {
        return user.name;
      }
    },
  },
  mounted() {
    this.$store.dispatch("logboek/getLoginLogs");
    this.$store.dispatch("logboek/getActionLogs");
    this.$store.dispatch("logboek/getUsers");
  },
};
</script>
