var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('b-loading',{model:{value:(_vm.ajaxloading.getEddiDashboard),callback:function ($$v) {_vm.$set(_vm.ajaxloading, "getEddiDashboard", $$v)},expression:"ajaxloading.getEddiDashboard"}}),_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"title is-5"},[_vm._v("Openstaande dossiers:")]),_c('div',{staticClass:"content"},[(_vm.eddiDashboard.open_dossiers.length)?_c('span',_vm._l((_vm.eddiDashboard.open_dossiers),function(dossier){return _c('li',{key:dossier.id},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                    name: 'dossier',
                    params: { dossier: dossier.slug }
                  })}}},[_vm._v(" "+_vm._s(dossier.slug))])])}),0):_c('span',[_vm._v("Geen openstaande dossiers")])])])])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column content ticket-container"},[_c('div',{staticClass:"title is-5"},[_vm._v("Beantwoorde tickets:")]),(_vm.eddiDashboard.beantwoorde_tickets.length)?_c('span',_vm._l((_vm.eddiDashboard.beantwoorde_tickets),function(ticket){return _c('a',{key:ticket.id,on:{"click":function($event){return _vm.$router.push({
                name: 'ticket',
                params: { ticket: ticket.id }
              })}}},[_c('li',{staticClass:"ticket",domProps:{"innerHTML":_vm._s(_vm.stripHTML(ticket.ticket))}})])}),0):_c('span',[_vm._v("Geen openstaande tickets")])])])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column content ticket-container"},[_c('div',{staticClass:"title is-5"},[_vm._v("Openstaande tickets:")]),(_vm.eddiDashboard.open_tickets.length)?_c('span',_vm._l((_vm.eddiDashboard.open_tickets),function(ticket){return _c('a',{key:ticket.id,on:{"click":function($event){return _vm.$router.push({
                name: 'ticket',
                params: { ticket: ticket.id }
              })}}},[_c('li',{staticClass:"ticket",domProps:{"innerHTML":_vm._s(_vm.stripHTML(ticket.ticket))}})])}),0):_c('span',[_vm._v("Geen openstaande tickets")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }