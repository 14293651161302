import axios from "axios";

export const getRegistratieStats = function({ dispatch, commit }) {
  const data = {};
  data.url = "get-registratie-stats";
  data.method = "GET";
  data.loading = "gettingregistratiestats";
  return dispatch("axiosApi", data, { root: true })
    .then(response => {
      commit("setRegistratieStats", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const aanmaken = function({ dispatch, rootGetters, commit }, payload) {
  const token = rootGetters["auth/token"];
  const user = rootGetters["auth/user"];

  dispatch("loadingOn", "rapportageaanmaken", { root: true });
  return axios({
    url: process.env.VUE_APP_API_URL + "/web/rapportage-aanmaken",
    method: "POST",
    data: payload,
    responseType: "blob",
    headers: {
      Authorization: "Bearer " + user.id + " " + token
    }
  })
    .then(function(response) {
      dispatch("loadingOff", "rapportageaanmaken", { root: true });
      commit("setNewRapportage", response.data);
      return Promise.resolve(response);
    })
    .catch(function(error) {
      if (error.response.status === 422) {
        // const response = { gemeente: ["Kies een gemeente"] };
        // dispatch("setValidationErrors", response, { root: true });
      }

      dispatch("loadingOff", "rapportageaanmaken", { root: true });
      return Promise.reject(error);
    });
};
