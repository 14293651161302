import { DateTime } from "luxon";

export const setNewDossier = function(state, payload) {
  state.dossiers.push(payload);
};

export const setDossiers = function(state, payload) {
  state.dossiers = payload;
};

export const deleteDossier = function(state, payload) {
  const array = state.dossiers;
  const id = payload.id;
  const index = array.findIndex(a => a.id === id);
  array.splice(index, 1);
};

export const setDossier = function(state, payload) {
  state.dossier = payload;
};

export const setNewRapportage = function(state, payload) {
  state.dossier.rapportages.push(payload);
};

export const updateRapportage = function(state, payload) {
  const array = state.dossier.rapportages;
  const id = payload.id;
  const index = array.findIndex(a => a.id === id);
  array.splice(index, 1, payload);
};

export const setDossierLogs = function(state, payload) {
  state.dossierLogs = payload;
};
