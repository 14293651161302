<template>
  <div>
    <b-field
      :label="hoofdletter(computedTitle)"
      :label-position="label"
      :type="{ 'is-danger': validation[modelKey] }"
      :message="validation[modelKey]"
    >
      <b-checkbox v-model="model[modelKey]" :size="size">{{
        computedCheckbox
      }}</b-checkbox>
    </b-field>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String
    },
    model: {
      required: true,
      type: Object
    },
    modelKey: {
      required: true,
      type: String
    },
    placeholder: {
      type: String
    },
    size: {
      default: "is-normal",
      type: String
    },
    label: {
      default: "",
      type: String
    },
    type: {
      default: "input",
      type: String
    },
    labelHidden: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {};
  },
  computed: {
    computedTitle() {
      if (this.labelHidden) {
        return "";
      }
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
    computedCheckbox() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    }
  },
  methods: {
    clearValidationError() {
      this.$store.dispatch("clearValidationError", this.modelKey);
    }
  },
  mounted() {}
};
</script>
