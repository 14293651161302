import router from "../../router";

export const registratieAanmaken = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "registratie-aanmaken";
  data.method = "POST";
  data.loading = "registratieaanmaken";
  data.data = payload;
  return dispatch("axiosApi", data, { root: true })
    .then(response => {
      commit("setNewRegistratie", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const getRegistraties = function({ dispatch, commit }) {
  const data = {};
  data.url = "get-registraties";
  data.method = "GET";
  data.loading = "gettingRegistraties";
  return dispatch("axiosApi", data, { root: true })
    .then(response => {
      commit("setRegistraties", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const getRegistratiesInbehandeling = function({ dispatch, commit }) {
  const data = {};
  data.url = "get-registraties-in-behandeling";
  data.method = "GET";
  data.loading = "gettingRegistraties";
  return dispatch("axiosApi", data, { root: true })
    .then(response => {
      commit("setRegistraties", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const registratieVerwijderen = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "registratie-verwijderen/" + payload.id;
  data.method = "POST";
  data.loading = "registratieverwijderen";
  data.data = payload;
  return dispatch("axiosApi", data, { root: true })
    .then(response => {
      commit("registratieVerwijderen", payload);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const showRegistratie = function({ dispatch, commit }, payload) {
  commit("setRegistratie", {});
  const data = {};
  data.url = "registratie-show/" + router.currentRoute.params.registratie;
  data.method = "GET";
  data.loading = "getShowRegistratie";

  return dispatch("axiosApi", data, { root: true })
    .then(response => {
      commit("setRegistratie", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const registratieAanpassen = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "registratie-aanpassen/" + router.currentRoute.params.registratie;
  data.method = "POST";
  data.loading = "editingregistratie";
  data.data = payload;
  return dispatch("axiosApi", data, { root: true })
    .then(response => {
      commit("updateRegistratie", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const notitieAanpassen = function({ dispatch, commit }, payload) {
  const data = {};
  data.url =
    "notitie-aanpassen/" + payload.registratie.id + "/" + payload.notitie.id;
  data.method = "POST";
  data.loading = "editingnotitie";
  data.data = payload.notitie;
  return dispatch("axiosApi", data, { root: true })
    .then(response => {
      commit("setRegistratie", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};
