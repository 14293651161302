<template>
  <div>
    <b-field
      :label="hoofdletter(computedTitle)"
      :label-position="label"
      :type="{ 'is-danger': validation[modelKey] }"
      :message="validation[modelKey]"
    >
      <b-select
        v-if="computedSelectKey"
        :placeholder="placeholder"
        expanded
        @input="clearValidationError()"
        v-model="model[modelKey]"
      >
        <option
          v-for="option of selectOptions"
          :key="option.id"
          :value="computedOption(option)"
          >{{ option[selectKey] }}</option
        >
      </b-select>

      <b-select
        v-else
        :placeholder="placeholder"
        expanded
        @input="clearValidationError()"
        v-model="model[modelKey]"
      >
        <option
          v-for="option of selectOptions"
          :key="option"
          :value="computedOption(option)"
          >{{ option }}</option
        >
      </b-select>
    </b-field>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String
    },
    model: {
      required: true,
      type: Object
    },
    modelKey: {
      required: true,
      type: String
    },
    placeholder: {
      type: String
    },
    size: {
      default: "is-normal",
      type: String
    },
    label: {
      default: "",
      type: String
    },
    type: {
      default: "input",
      type: String
    },
    selectOptions: {
      required: true,
      type: Array
    },
    selectKey: {
      type: String
    },
    selectValue: {
      type: String
    }
  },
  data() {
    return {};
  },
  computed: {
    computedTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
    computedSelectKey() {
      if (this.selectKey) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    clearValidationError() {
      this.$emit("change");
      this.$store.dispatch("clearValidationError", this.modelKey);
    },
    computedOption(option) {
      if (this.selectValue) {
        return option[this.selectValue];
      } else {
        return option;
      }
    }
  },
  mounted() {}
};
</script>
