import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import buefy from "buefy";
// import "buefy/dist/buefy.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowDown,
  faArrowUp,
  faBold,
  faCalendar,
  faCamera,
  faCircle,
  faCode,
  faEdit,
  faEnvelope,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faHeading,
  faInfoCircle,
  faItalic,
  faListOl,
  faListUl,
  faMinus,
  faPlus,
  faQuoteLeft,
  faRedo,
  faReply,
  faSearch,
  faStrikethrough,
  faTag,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnderline,
  faUndo,
  faUpload,
  faUser,
  faUserSlash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faAngleLeft,
  faAngleRight,
  faTimes,
  faTrash,
  faExclamationCircle,
  faEye,
  faUser,
  faEyeSlash,
  faArrowUp,
  faArrowDown,
  faUpload,
  faTag,
  faEdit,
  faPlus,
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faCode,
  faHeading,
  faListUl,
  faListOl,
  faQuoteLeft,
  faUndo,
  faRedo,
  faMinus,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faReply,
  faCalendar,
  faTimesCircle,
  faSearch,
  faCamera,
  faEnvelope,
  faUserSlash,
  faArchive,
  faInfoCircle,
  faExclamationTriangle
);

Vue.component("icon", FontAwesomeIcon);

import customInput from "@/components/form/input.vue";
import formSelect from "@/components/form/formSelect.vue";
import formDatepicker from "@/components/form/formDatePicker.vue";
import pictureUpload from "@/components/form/pictureUpload.vue";
import formTags from "@/components/form/tags.vue";
import formTagsAutocomplete from "@/components/form/tagsAutocomplete.vue";
import formButton from "@/components/form/formButton.vue";
import showModel from "@/components/form/showModel.vue";
import formCheckbox from "@/components/form/formCheckbox.vue";
import formEditor from "@/components/form/editor/Editor.vue";

Vue.component("formInput", customInput);
Vue.component("showModel", showModel);
Vue.component("formSelect", formSelect);
Vue.component("formPictureUpload", pictureUpload);
Vue.component("formTags", formTags);
Vue.component("formTagsAutocomplete", formTagsAutocomplete);
Vue.component("formButton", formButton);
Vue.component("formCheckbox", formCheckbox);
Vue.component("formEditor", formEditor);
Vue.component("formDatepicker", formDatepicker);

import { axiosrequest } from "@/globals/interceptors.js";
import { axiosresponse } from "@/globals/interceptors.js";

import vuemixins from "@/globals/mixins/mixins.js";
Vue.mixin(vuemixins);

Vue.config.productionTip = false;
Vue.use(buefy, {
  defaultIconComponent: "icon",
  defaultIconPack: "fas"
});

import routerMiddleware from "@/globals/middleware/beforeEach";
router.beforeEach(routerMiddleware);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
