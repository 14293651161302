<template>
  <div
    :class="['navbar-item has-dropdown', { 'is-active': showDropdown }]"
    @mouseover="mouseOverDropdown('showDropDown')"
    @mouseleave="mouseLeaveDropdown('showDropDown')"
  >
    <router-link
      :class="['navbar-link']"
      :to="{ name: link.routerLink }"
      @click.native="mainLinkClicked()"
    >
      {{ link.naam }}
    </router-link>
    <span class="is-hidden-desktop" v-if="showDropdown">
      <div class="navbar-dropdown is-boxed">
        <span v-for="child of link.children" :key="child.naam">
          <router-link
            v-if="child.routerLink"
            @click.native="navigateToLink()"
            class="navbar-item"
            :to="{ name: child.routerLink }"
            :exact="child.exact"
          >
            {{ child.naam }}
          </router-link>
          <hr v-if="child.divider" class="navbar-divider" />
          <a v-if="child.emit" class="navbar-item" @click="emit(child.emit)">
            {{ child.naam }}
          </a>
        </span>
      </div>
    </span>

    <span class="is-hidden-touch" v-if="showDropdown">
      <div class="navbar-dropdown is-boxed">
        <span v-for="child of link.children" :key="child.naam">
          <router-link
            v-if="child.routerLink"
            @click.native="navigateToLink()"
            class="navbar-item"
            :to="{ name: child.routerLink }"
            :exact="child.exact"
          >
            {{ child.naam }}
          </router-link>
          <hr v-if="child.divider" class="navbar-divider" />
          <a v-if="child.emit" class="navbar-item" @click="emit(child.emit)">
            {{ child.naam }}
          </a>
        </span>
      </div>
    </span>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    link: {
      required: true,
      type: Object
    },
    showMobileParent: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      showDropdown: false
    };
  },
  computed: {},
  methods: {
    mainLinkClicked() {
      if (this.showDropdown) {
        this.showDropdown = false;
      } else {
        this.showDropdown = true;
      }
    },
    emit(emit) {
      this.$emit(emit);
    },
    navigateToLink() {
      this.$emit("closedMobileMenu", true);
      this.showDropdown = false;
      this.closeDropDown();
    },
    mouseOverDropdown() {
      this.showDropdown = true;
    },
    mouseLeaveDropdown() {
      this.showDropdown = false;
    },
    closeDropDown() {
      this.showDropdown = false;
    }
  },
  watch: {
    showMobileParent() {
      if (this.showMobileParent) {
        this.showDropdown = true;
      }

      if (!this.showMobileParent) {
        this.showDropdown = false;
      }
    }
  },
  mounted() {}
};
</script>
