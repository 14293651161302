var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-loading',{model:{value:(_vm.ajaxloading.getVizierdashboard),callback:function ($$v) {_vm.$set(_vm.ajaxloading, "getVizierdashboard", $$v)},expression:"ajaxloading.getVizierdashboard"}}),_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column content ticket-container"},[_c('div',{staticClass:"title is-5"},[_vm._v("Nog niet beantwoorde tickets:")]),(_vm.vizierdashboard.niet_beantwoorde_tickets.length)?_c('span',_vm._l((_vm.vizierdashboard.niet_beantwoorde_tickets),function(ticket){return _c('a',{key:ticket.id,on:{"click":function($event){return _vm.$router.push({
                name: 'ticket',
                params: { ticket: ticket.id },
              })}}},[_c('li',{staticClass:"ticket",domProps:{"innerHTML":_vm._s(_vm.stripHTML(ticket.ticket))}})])}),0):_c('span',[_vm._v("Geen openstaande tickets zonder antwoord.")])])])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column content ticket-container"},[_c('div',{staticClass:"title is-5"},[_vm._v("Openstaande beantwoorde tickets:")]),(_vm.vizierdashboard.open_tickets.length)?_c('span',_vm._l((_vm.vizierdashboard.open_tickets),function(ticket){return _c('a',{key:ticket.id,on:{"click":function($event){return _vm.$router.push({
                name: 'ticket',
                params: { ticket: ticket.id },
              })}}},[_c('li',{staticClass:"ticket",domProps:{"innerHTML":_vm._s(_vm.stripHTML(ticket.ticket))}})])}),0):_c('span',[_vm._v("Geen openstaande, reeds beantwoorde, tickets.")])])])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"title is-5"},[_vm._v("Openstaande dossiers:")]),_c('div',{staticClass:"content"},[(_vm.vizierdashboard.open_dossiers.length)?_c('span',_vm._l((_vm.vizierdashboard.open_dossiers),function(dossier){return _c('li',{key:dossier.id},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                    name: 'dossier',
                    params: { dossier: dossier.slug },
                  })}}},[_vm._v(" "+_vm._s(dossier.slug))])])}),0):_c('span',[_vm._v("Geen openstaande dossiers")])])])])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"title is-5"},[_vm._v("Laatst ingelogde coaches:")]),_c('div',{staticClass:"content"},[(_vm.eddis.length)?_c('span',_vm._l((_vm.eddis),function(eddi){return _c('li',{key:eddi.id},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                    name: 'eddi',
                    params: { eddi: eddi.id },
                  })}}},[_vm._v(" "+_vm._s(eddi.name)+" "),(eddi.last_login)?_c('span',[_vm._v(" op "+_vm._s(_vm.formatDatePickerDateAndTime(eddi.last_login)))]):_c('span',[_vm._v(" is nog niet eerder ingelogd.")])])])}),0):_c('span',[_vm._v("Nog geen coaches's aangemaakt")])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }