<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.datePicker {
  margin-bottom: 12px;
}
</style>

<template>
  <div>
    <div v-if="$route.name === 'eddi-toevoegen'" class="users-title">
      Eddi toevoegen
    </div>
    <div v-else class="users-title">Eddi aanpassen</div>

    <br />

    <form @submit.prevent="submit()">
      <br />
      <div class="title has-text-centered">Naam coach</div>
      <div class="columns">
        <div class="column">
          <formInput
            :model="form"
            modelKey="voornaam"
            placeholder="Voornaam van coach"
          />

          <formInput
            :model="form"
            modelKey="achternaam"
            placeholder="Achternaam van coach"
          />
        </div>
      </div>
      <hr />
      <div class="title has-text-centered">Contactgegevens</div>
      <formInput
        :model="form"
        modelKey="email"
        placeholder="info@vizieroost.nl"
      />

      <formInput
        :model="form"
        modelKey="telefoonnummer"
        placeholder="0612345678"
      />

      <br />

      <b-field>
        <b-checkbox
          v-if="$router.currentRoute.name === 'eddi-toevoegen'"
          v-model="form.uitnodigen"
          >Uitnodigingsmail sturen</b-checkbox
        >
      </b-field>

      <b-button
        native-type="submit"
        :loading="ajaxloading.eddiedit"
        type="is-primary is-rounded"
        ><span v-if="$router.currentRoute.name === 'eddi-toevoegen'"
          >Coach toevoegen</span
        ><span v-else>Coach aanpassen</span></b-button
      >

      <b-button type="is-rounded" @click="$router.go(-1)">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        id: 0,
        uitnodigen: true,
      },
    };
  },
  computed: {
    settings() {
      return this.$store.getters["auth/settings"];
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    item() {
      return this.$store.getters["eddis/eddi"];
    },
    gemeenten() {
      if (this.settings.gemeenten) {
        return this.settings["gemeenten"];
      }
      return [];
    },
    filteredGemeentes() {
      if (!this.gemeenten.length) {
        return this.gemeenten;
      }

      if (!this.form.gemeente) {
        return this.gemeenten;
      } else {
        return this.gemeenten.filter((option) => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.form.gemeente.toLowerCase()) >= 0
          );
        });
      }
    },
  },
  methods: {
    submit() {
      if (this.$router.currentRoute.name === "eddi-toevoegen") {
        this.toevoegen();
      } else {
        this.aanpassen();
      }
    },
    aanpassen() {
      this.$store
        .dispatch("eddis/eddiAanpassen", this.form)
        .then((response) => {
          this.$router.push({
            name: "eddi",
            params: { eddi: response.data.id },
          });
        })
        .catch((error) => {
          const element = document.getElementById(
            Object.keys(error.response.data.errors)[0]
          );
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        });
    },
    toevoegen() {
      this.$store
        .dispatch("eddis/eddiToevoegen", this.form)
        .then((response) => {
          this.$router.push({
            name: "eddi",
            params: { eddi: response.data.id },
          });
        })
        .catch((error) => {
          const element = document.getElementById(
            Object.keys(error.response.data.errors)[0]
          );
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        });
    },
  },
  mounted() {
    if (this.$router.currentRoute.name === "eddi-aanpassen") {
      if (this.item.id) {
        this.form = this.item;
      } else {
        this.$store
          .dispatch("eddis/getEddi", this.$route.params.eddi)
          .then(() => {
            this.form = this.item;
          });
      }
    }
  },
};
</script>
