<style scoped>
.ticket-container {
  white-space: nowrap;
  overflow: hidden !important;
}

.ticket {
  text-overflow: ellipsis !important;
  /* white-space: nowrap; */
  overflow: hidden !important;
}

.column {
  width: inherit;
}
</style>

<template>
  <div>
    <b-loading v-model="ajaxloading.getVizierdashboard" />
    <div class="box">
      <div class="columns">
        <div class="column content ticket-container">
          <div class="title is-5">Nog niet beantwoorde tickets:</div>
          <span v-if="vizierdashboard.niet_beantwoorde_tickets.length">
            <a
              v-for="ticket in vizierdashboard.niet_beantwoorde_tickets"
              :key="ticket.id"
              @click="
                $router.push({
                  name: 'ticket',
                  params: { ticket: ticket.id },
                })
              "
            >
              <li v-html="stripHTML(ticket.ticket)" class="ticket"></li>
            </a>
          </span>
          <span v-else>Geen openstaande tickets zonder antwoord.</span>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="columns">
        <div class="column content ticket-container">
          <div class="title is-5">Openstaande beantwoorde tickets:</div>
          <span v-if="vizierdashboard.open_tickets.length">
            <a
              v-for="ticket in vizierdashboard.open_tickets"
              :key="ticket.id"
              @click="
                $router.push({
                  name: 'ticket',
                  params: { ticket: ticket.id },
                })
              "
            >
              <li v-html="stripHTML(ticket.ticket)" class="ticket"></li
            ></a>
          </span>
          <span v-else>Geen openstaande, reeds beantwoorde, tickets.</span>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="columns">
        <div class="column">
          <div class="title is-5">Openstaande dossiers:</div>
          <div class="content">
            <span v-if="vizierdashboard.open_dossiers.length">
              <li
                v-for="dossier in vizierdashboard.open_dossiers"
                :key="dossier.id"
              >
                <a
                  @click="
                    $router.push({
                      name: 'dossier',
                      params: { dossier: dossier.slug },
                    })
                  "
                >
                  {{ dossier.slug }}</a
                >
              </li>
            </span>
            <span v-else>Geen openstaande dossiers</span>
          </div>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="columns">
        <div class="column">
          <div class="title is-5">Laatst ingelogde coaches:</div>
          <div class="content">
            <span v-if="eddis.length">
              <li v-for="eddi in eddis" :key="eddi.id">
                <a
                  @click="
                    $router.push({
                      name: 'eddi',
                      params: { eddi: eddi.id },
                    })
                  "
                >
                  {{ eddi.name }}
                  <span v-if="eddi.last_login">
                    op
                    {{ formatDatePickerDateAndTime(eddi.last_login) }}</span
                  ><span v-else> is nog niet eerder ingelogd.</span></a
                >
              </li>
            </span>
            <span v-else>Nog geen coaches's aangemaakt</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {
    vizierdashboard() {
      return this.$store.getters["vizierdashboard"];
    },
    eddis() {
      if (this.vizierdashboard.ingelogde_eddis) {
        const eddis = this.vizierdashboard.ingelogde_eddis;
        return eddis.sort((a, b) => {
          return new Date(b.last_login) - new Date(a.last_login);
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    stripHTML(payload) {
      if (payload) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = payload;
        return tmp.textContent || tmp.innerText || "";
      }
    },
  },
  mounted() {
    this.$store.dispatch("getVizierDashboard");
  },
};
</script>
