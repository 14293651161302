<template>
  <div>
    <b-field
      :id="modelKey"
      :label="hoofdletter(computedTitle)"
      :label-position="label"
      :type="{ 'is-danger': validation[computedValidation] }"
      :message="validation[computedValidation]"
    >
      <b-datepicker
        v-on:input="dateChanged()"
        :size="size"
        v-model="date"
        locale="nl-NL"
        rounded
        :placeholder="placeholder"
        icon="calendar"
        :first-day-of-week="1"
        :mobile-native="false"
      >
      </b-datepicker>
    </b-field>
  </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
  components: {},
  props: {
    title: {
      type: String
    },
    model: {
      required: true,
      type: Object
    },
    modelKey: {
      required: true,
      type: String
    },
    placeholder: {
      type: String
    },
    validationKey: {
      type: String
    },
    size: {
      default: "is-normal",
      type: String
    },
    label: {
      default: "",
      type: String
    },
    type: {
      default: "input",
      type: String
    }
  },
  data() {
    return {
      date: null
    };
  },
  computed: {
    computedTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
    computedValidation() {
      if (this.validationKey) {
        return this.validationKey;
      } else {
        return this.modelKey;
      }
    },
    computedDate() {
      return this.model[this.modelKey];
    }
  },
  methods: {
    blur() {
      this.$emit("blur");
    },
    clearValidationError() {
      this.$emit("input");
      this.$store.dispatch("clearValidationError", this.modelKey);
    },
    dateChanged() {
      this.model[this.modelKey] = this.date;
    }
  },
  watch: {
    computedDate() {
      if (this.date == null) {
        this.date = this.formatDataBaseDateToJSDate(this.model[this.modelKey]);
      }
    }
  },
  mounted() {
    this.date = this.formatDataBaseDateToJSDate(this.model[this.modelKey]);
  }
};
</script>
