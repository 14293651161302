export const setTickets = function(state, payload) {
  state.tickets = payload;
};

export const setTicket = function(state, payload) {
  state.ticket = payload;
};

export const setNewTicket = function(state, payload) {
  state.tickets.push(payload);
};
