<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.datePicker {
  margin-bottom: 12px;
}
</style>

<template>
  <div class="container box">
    <div class="users-title">Foto aanpassen</div>

    <br />

    <form>
      <br />
      <br />

      <formPictureUpload
        title="Foto voor coach"
        :model="formData"
        modelKey="foto"
        size="is-small"
        :preview="true"
        :existingPicture="eddi.picture"
        :linkToExistingPictureBase="
          env.VUE_APP_API_URL + '/web/get-eddi-picture'
        "
      />

      <br />

      <b-button
        @click="submit()"
        :loading="ajaxloading.eddiefotoaanpassen"
        type="is-primary is-rounded"
        >Foto uploaden</b-button
      >
      &nbsp;

      <b-button
        v-if="eddi.picture"
        @click="deletePicture()"
        :loading="ajaxloading.deletePicture"
        type="is-danger is-rounded"
        >Foto verwijderen</b-button
      >

      &nbsp;

      <b-button type="is-rounded" @click="$router.go(-1)">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {},
      formData: new FormData(),
      env: process.env,
    };
  },
  computed: {
    eddi() {
      return this.$store.getters["eddis/eddi"];
    },
  },
  methods: {
    submit() {
      this.formData.append("id", this.eddi.id);
      this.$store.dispatch("eddis/editEddiPicture", this.formData).then(() => {
        this.$router.go(-1);
      });
    },
    deletePicture() {
      this.$store.dispatch("eddis/deleteEddiPicture", this.eddi).then(() => {
        this.eddi.picture = "";
      });
    },
  },
  mounted() {
    if (!this.eddi.id) {
      this.$store.dispatch("eddis/getEddi", this.$route.params.eddi);
    }
  },
};
</script>
