export const getEddis = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-eddis";
  data.method = "GET";
  data.loading = "gettingEddis";
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setEddis", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const eddiToevoegen = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "eddi-toevoegen";
  data.method = "POST";
  data.loading = "eddiedit";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setEddi", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const eddiAanpassen = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "eddi-aanpassen/" + payload.id;
  data.method = "POST";
  data.loading = "eddiedit";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setUpdatedEddi", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const searchEddis = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "eddi-zoeken";
  data.method = "POST";
  data.loading = "eddiedit";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const getEddi = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-eddi/" + payload;
  data.method = "GET";
  data.loading = "gettingeddi";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setEddi", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const deleteEddiPicture = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "delete-eddi-picture/" + payload.id;
  data.method = "POST";
  data.loading = "deletePicture";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const editEddiPicture = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "eddi-foto-aanpassen/" + payload.get("id");
  data.method = "POST";
  data.loading = "eddiefotoaanpassen";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};
