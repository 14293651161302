import router from "../../router";

export const getGebruikers = function({ dispatch, commit }) {
  const data = {};
  data.url = "get-gebruikers";
  data.method = "GET";
  data.loading = "gettingGebruikers";
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setGebruikers", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const gebruikerToevoegen = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "gebruiker-toevoegen";
  data.method = "POST";
  data.loading = "gebruikertoevoegen";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setNewUser", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const gebruikerAanpassen = function(
  { rootGetters, dispatch, commit },
  payload
) {
  const data = {};
  data.url = "gebruiker-aanpassen/" + payload.id;
  data.method = "POST";
  data.loading = "gebruikerAanpassen";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("changeUserStatus", response.data);
      if (response.data.id === rootGetters["auth/user"]["id"]) {
        dispatch("auth/getLoggedInUser", "", { root: true });
      }
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const gebruikerDeactiveren = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "gebruiker-deactiveren/" + payload.id;
  data.method = "POST";
  data.loading = "gebruikerdeactiveren";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("changeUserStatus", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const gebruikerActiveren = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "gebruiker-activeren/" + payload.id;
  data.method = "POST";
  data.loading = "gebruikeractiveren";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("changeUserStatus", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const gebruikerArchiveren = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "gebruiker-archiveren/" + payload.id;
  data.method = "POST";
  data.loading = "gebruikerarchiveren";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("changeUserStatus", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const getUser = function({ dispatch, commit }, payload) {
  commit("setGebruiker", {});
  const data = {};
  data.url = "gebruiker-show/" + router.currentRoute.params.gebruiker;
  data.method = "GET";
  data.loading = "getEditUser";
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setGebruiker", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};
