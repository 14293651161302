import { DateTime } from "luxon";

export const setActionLogs = function(state, payload) {
  // for (let log of payload) {
  //   if (log.datum) {
  //     let datum = DateTime.fromISO(log.datum.replace(" ", "T"), {
  //       zone: "utc"
  //     });
  //     log.datum = datum
  //       .setLocale("nl")
  //       .setZone("Europe/Amsterdam")
  //       .toLocaleString({
  //         day: "numeric",
  //         month: "numeric",
  //         year: "numeric",
  //         hour: "numeric",
  //         minute: "2-digit"
  //       });
  //   }
  state.actieLogs = payload;
  // }
};

export const setInzageLogs = function(state, payload) {
  // for (let log of payload) {
  //   if (log.datum) {
  //     let datum = DateTime.fromISO(log.datum.replace(" ", "T"), {
  //       zone: "utc"
  //     });
  //     log.datum = datum
  //       .setLocale("nl")
  //       .setZone("Europe/Amsterdam")
  //       .toLocaleString({
  //         day: "numeric",
  //         month: "numeric",
  //         year: "numeric",
  //         hour: "numeric",
  //         minute: "2-digit"
  //       });
  //   }
  state.inzageLogs = payload;
  // }
};

export const setLoginLogs = function(state, payload) {
  // for (let log of payload) {
  //   if (log.datum) {
  //     let datum = DateTime.fromISO(log.datum.replace(" ", "T"), {
  //       zone: "utc"
  //     });
  //     log.datum = datum
  //       .setLocale("nl")
  //       .setZone("Europe/Amsterdam")
  //       .toLocaleString({
  //         day: "numeric",
  //         month: "numeric",
  //         year: "numeric",
  //         hour: "numeric",
  //         minute: "2-digit"
  //       });
  //   }
  // }
  state.loginLogs = payload;
};

export const setUsers = function(state, payload) {
  state.users = payload;
};
