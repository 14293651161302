import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import state from "./state";
import * as mutations from "./mutations";
import * as getters from "./getters";
import * as actions from "./actions";

import auth from "@/store/auth";
import gebruikers from "@/store/gebruikers";
import registraties from "@/store/registraties";
import logboek from "@/store/logboek";
import rapportages from "@/store/rapportages";
import dossiers from "@/store/dossiers";
import eddis from "@/store/eddis";
import tickets from "@/store/tickets";

export default new Vuex.Store({
  modules: {
    auth,
    gebruikers,
    registraties,
    logboek,
    rapportages,
    tickets,
    eddis,
    dossiers
  },
  state,
  mutations,
  getters,
  actions
});
