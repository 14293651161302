<template>
  <div>
    <nav class="navbar is-primary">
      <div class="container">
        <div class="navbar-brand">
          <a
            @click="toggleMobile()"
            role="button"
            class="navbar-burger"
            aria-label="menu"
            aria-expanded="true"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div :class="['navbar-menu', { 'is-active': showMobile }]">
          <div class="navbar-start">
            <div class="navbar-item">Coaches | Vizieroost</div>
            <singleLink
              :link="{ naam: 'Dashboard', routerLink: 'app', exact: true }"
              v-on:closedMobileMenu="closeMobile()"
            ></singleLink>

            <singleLink
              :link="{
                naam: 'Dossiers',
                routerLink: 'dossiers-index',
              }"
              v-on:closedMobileMenu="closeMobile()"
            ></singleLink>

            <singleLink
              :link="{
                naam: 'Tickets',
                routerLink: 'tickets-index',
              }"
              v-on:closedMobileMenu="closeMobile()"
            ></singleLink>

            <singleLink
              v-if="user.rol !== 'eddi'"
              :link="{
                naam: `Coaches`,
                routerLink: 'eddi-index',
              }"
              v-on:closedMobileMenu="closeMobile()"
            ></singleLink>

            <singleLink
              v-if="user.rol !== 'eddi'"
              :link="{
                naam: `Rapportages`,
                routerLink: 'rapportages-index',
              }"
              v-on:closedMobileMenu="closeMobile()"
            ></singleLink>

            <singleLink
              v-if="user.rol !== 'eddi'"
              :link="{
                naam: `Logboek`,
                routerLink: 'logboek-index',
              }"
              v-on:closedMobileMenu="closeMobile()"
            ></singleLink>
          </div>
          <div class="navbar-end" @click="closeMobile()">
            <singleLink
              v-if="user.rol === 'admin' || user.rol === 'superuser'"
              :link="{
                naam: `Gebruikers`,
                routerLink: 'gebruikers-index',
              }"
              v-on:closedMobileMenu="closeMobile()"
            ></singleLink>

            <dropDownLink
              :showMobileParent="showMobile"
              v-on:closedMobileMenu="closeMobile()"
              v-on:logout="logout()"
              :link="{
                naam: user.name,
                routerLink: 'instellingen',
                exact: true,
                children: [
                  {
                    naam: 'Instellingen',
                    routerLink: 'instellingen',
                    exact: true,
                  },
                  {
                    naam: 'Foto aanpassen',
                    routerLink: 'instellingen-foto-aanpassen',
                  },
                  {
                    naam: 'Apparaten',
                    routerLink: 'instellingen-apparaten',
                  },
                  {
                    divider: true,
                  },
                  {
                    naam: 'Logout',
                    emit: 'logout',
                  },
                ],
              }"
            ></dropDownLink>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import singleLink from "@/components/router/SingleLink.vue";
import dropDownLink from "@/components/router/DropDownLink.vue";

export default {
  components: {
    singleLink,
    dropDownLink,
  },
  props: [],
  data() {
    return {
      showMobile: false,
      activeUserDropDown: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({ name: "inloggen" });
        this.$store.dispatch("createFlashBanner", {
          message: "U bent succesvol uitgelogd!",
          timeout: 5,
        });
      });
    },
    toggleMobile() {
      if (this.showMobile) {
        this.showMobile = false;
      } else {
        this.showMobile = true;
      }
    },
    closeUserDropdown() {
      this.activeUserDropDown = false;
    },
    closeMobile() {
      this.showMobile = false;
    },
  },
  mounted() {},
};
</script>
