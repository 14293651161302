<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.datePicker {
  margin-bottom: 12px;
}
</style>

<template>
  <div>
    <div class="users-title">
      Ticket aanmaken
    </div>

    <br />

    <form @submit.prevent="submit()">
      <br />
      <br />
      <formCheckbox
        :model="form"
        :labelHidden="true"
        title="Ticket voor een dossier"
        modelKey="dossier"
      />
      <span v-if="form.dossier">
        <br />
        <formSelect
          placeholder="Kies dossier"
          :model="form"
          title="Kies dossier"
          modelKey="dossier_keuze"
          :selectOptions="dossiers"
          selectKey="dossier_naam"
          selectValue="id"
        />
      </span>
      <br />
      <formEditor modelKey="ticket" title=" " :model="form" />
      <br />
      <b-button
        native-type="submit"
        :loading="ajaxloading.createTicket"
        type="is-primary is-rounded"
      >
        Ticket aanmaken</b-button
      >

      <b-button type="is-rounded" @click="$router.go(-1)">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        id: 0,
        dossier: true
      }
    };
  },
  computed: {
    settings() {
      return this.$store.getters["auth/settings"];
    },
    dossiers() {
      return this.$store.getters["dossiers/dossiers"];
    }
  },
  methods: {
    submit() {
      this.$store
        .dispatch("tickets/createTicket", this.form)
        .then(response => {
          this.$router.push({
            name: "tickets-index"
          });
        })
        .catch(error => {
          const element = document.getElementById(
            Object.keys(error.response.data.errors)[0]
          );
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        });
    }
  },
  mounted() {
    this.$store.dispatch("dossiers/getDossiersInbehandeling");
  }
};
</script>
