export const getActiveTickets = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-active-tickets";
  data.method = "GET";
  data.loading = "gettingTickets";
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setTickets", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const getTickets = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-tickets";
  data.method = "GET";
  data.loading = "gettingTickets";
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setTickets", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const getTicket = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-ticket/" + payload;
  data.method = "GET";
  data.loading = "gettingTicket";
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setTicket", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const createComment = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "create-ticket-comment/" + payload.ticket.id;
  data.method = "POST";
  data.loading = "createComment";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setTicket", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const createTicket = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "create-ticket";
  data.method = "POST";
  data.loading = "createTicket";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setNewTicket", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};
