import { DateTime } from "luxon";

export const setNewRegistratie = function(state, payload) {
  state.registraties.push(payload);
};

export const setRegistraties = function(state, payload) {
  for (let registratie of payload) {
    if (registratie.datum_melding) {
      let datum = DateTime.fromISO(
        registratie.datum_melding.replace(" ", "T"),
        {
          zone: "utc"
        }
      );
      registratie.datum_melding = datum
        .setLocale("nl")
        .setZone("Europe/Amsterdam")
        .toLocaleString({
          day: "numeric",
          month: "numeric",
          year: "numeric"
        });
    }

    if (registratie.datum_incident) {
      let datum = DateTime.fromISO(
        registratie.datum_incident.replace(" ", "T"),
        {
          zone: "utc"
        }
      );
      registratie.datum_incident = datum
        .setLocale("nl")
        .setZone("Europe/Amsterdam")
        .toLocaleString({
          day: "numeric",
          month: "numeric",
          year: "numeric"
        });
    }
  }
  state.registraties = payload;
};

export const registratieVerwijderen = function(state, payload) {
  const array = state.registraties;
  const id = payload.id;
  const index = array.findIndex(a => a.id === id);
  array.splice(index, 1);
};

export const setRegistratie = function(state, payload) {
  for (let notitie in payload.notities) {
    let datum = DateTime.fromISO(payload.notities[notitie].created_at, {
      zone: "utc"
    });

    payload.notities[notitie].created_at = datum
      .setLocale("nl")
      .setZone("Europe/Amsterdam")
      .toLocaleString({
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit"
      });
  }

  if (payload.datum_melding) {
    payload.datum_melding = DateTime.fromISO(
      payload.datum_melding.replace(" ", "T"),
      {
        zone: "utc"
      }
    )
      .setLocale("nl")
      .setZone("Europe/Amsterdam")
      .toJSDate();
  }

  if (payload.datum_incident) {
    payload.datum_incident = DateTime.fromISO(
      payload.datum_incident.replace(" ", "T"),
      {
        zone: "utc"
      }
    )
      .setLocale("nl")
      .setZone("Europe/Amsterdam")
      .toJSDate();
  }

  if (payload.tijd_incident) {
    payload.tijd_incident = DateTime.fromISO(
      payload.tijd_incident.replace(" ", "T"),
      {
        zone: "utc"
      }
    )
      .setLocale("nl")
      .setZone("Europe/Amsterdam")
      .toJSDate();
  }

  state.registratie = payload;
};

export const updateRegistratie = function(state, payload) {
  const array = state.registraties;
  const id = payload.id;
  const index = array.findIndex(a => a.id === id);
  array.splice(index, 1, payload);
};
