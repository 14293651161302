<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}
</style>

<template>
  <div class="content">
    <div class="users-title">Gebruiker {{ gebruiker.name }} aanpassen</div>
    <form @submit.prevent="gebruikerAanpassen()">
      <b-loading v-model="ajaxloading.getEditUser" />
      <br />
      <br />

      <b-field
        label="Voornaam"
        :type="{ 'is-danger': validation.voornaam }"
        :message="{ [validation.voornaam]: validation.voornaam }"
      >
        <b-input
          type="input"
          rounded
          placeholder="Voornaam"
          v-model="form.voornaam"
        >
        </b-input>
      </b-field>

      <b-field
        label="Achternaam"
        :type="{ 'is-danger': validation.achternaam }"
        :message="{ [validation.achternaam]: validation.achternaam }"
      >
        <b-input
          type="input"
          rounded
          placeholder="Achternaam"
          v-model="form.achternaam"
        >
        </b-input>
      </b-field>

      <b-field
        label="Email"
        :type="{ 'is-danger': validation.email }"
        :message="{ [validation.email]: validation.email }"
      >
        <b-input
          v-model="form.email"
          rounded
          placeholder="Emailadres"
        ></b-input>
      </b-field>

      <b-field
        label="Telefoonnummer"
        :type="{ 'is-danger': validation.telefoonnummer }"
        :message="{ [validation.telefoonnummer]: validation.telefoonnummer }"
      >
        <b-input
          v-model="form.telefoonnummer"
          rounded
          placeholder="Telefoonnummer"
        ></b-input>
      </b-field>

      <b-field
        label="Rol"
        :type="{ 'is-danger': validation.rol }"
        :message="{ [validation.rol]: validation.rol }"
      >
        <b-select
          v-if="settings.gebruikers"
          placeholder="Selecteer een rol"
          v-model="form.rol"
          rounded
          expanded
        >
          <option
            v-for="rol of settings.gebruikers.rollen"
            :key="rol"
            :value="rol"
            >{{ hoofdletter(rol) }}</option
          >
        </b-select>
      </b-field>

      <h5>Notificaties</h5>

      <div class="columns">
        <div class="column">
          <b-checkbox v-model="form.ticket_notificaties">
            Ticket Notificaties
          </b-checkbox>
        </div>
      </div>

      <br />

      <b-button
        native-type="submit"
        :loading="ajaxloading.gebruikerAanpassen"
        type="is-primary is-rounded"
        >Gebruiker aanpassen</b-button
      >&nbsp;

      <b-button type="is-rounded" @click="$router.go(-1)">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {}
    };
  },
  computed: {
    gebruiker() {
      return this.$store.getters["gebruikers/gebruiker"];
    },
    settings() {
      return this.$store.getters["auth/settings"];
    }
  },
  methods: {
    gebruikerAanpassen() {
      this.$store
        .dispatch("gebruikers/gebruikerAanpassen", this.form)
        .then(() => {
          this.$store.dispatch("createFlashBanner", {
            message: "Gebruiker aangepast.",
            timeout: 3
          });
          this.$router.push({ name: "gebruikers-index" });
        });
    }
  },
  watch: {
    gebruiker: function(val) {
      this.form = this.gebruiker;
      if (this.gebruiker.settings) {
        this.form.ticket_notificaties = this.gebruiker.settings.ticket_notificaties;
      }
    }
  },
  mounted() {
    this.$store.dispatch("gebruikers/getUser");
  }
};
</script>
