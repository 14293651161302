export const login = function({ dispatch, commit }, payload) {
  commit("setToken", null);
  const data = {};
  data.url = "login";
  data.method = "POST";
  data.loading = "inloggen";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      if (response.data.user) {
        commit("setUser", response.data.user);
      }
      if (response.data.token) {
        commit("setToken", response.data.token);
      }
      if (response.data.loginToken) {
        commit("setLoginToken", response.data.loginToken);
      }
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const logout = function({ dispatch, commit }) {
  commit("setUser", {});
  const data = {};
  data.url = "logout";
  data.method = "POST";
  data.loading = "uitloggen";
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const resetPassword = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "resetpassword";
  data.method = "POST";
  data.loading = "sendingresetpasswordemail";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const changePassword = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "change-password-with-token";
  data.method = "POST";
  data.loading = "changingpassword";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(firstresponse => {
      return dispatch("getLoggedInUser", { root: true }).then(response => {
        return Promise.resolve(firstresponse);
      });
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const validatingcode = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "validate-2fa-code";
  data.method = "POST";
  data.loading = "validatingcode";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setToken", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const getTokenBeforeMount = function(
  { state, dispatch, commit },
  payload
) {
  commit("setQueuedActions", payload);
  if (state.token === "REQUESTING") {
    return;
  }

  if (state.token === undefined) {
    commit("setToken", "REQUESTING");
    return dispatch("getLoggedInUser").then(() => {
      while (state.queuedActions.length > 0) {
        dispatch(state.queuedActions[0], "", { root: true });
        commit("removeActionFromQueue");
      }
    });
  } else {
    while (state.queuedActions.length > 0) {
      dispatch(state.queuedActions[0], "", { root: true });
      commit("removeActionFromQueue");
    }
  }
};

export const getSettings = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-settings";
  data.method = "GET";
  data.loading = "gettingsettings";
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setSettings", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const getLoggedInUser = function({ dispatch, commit }) {
  const data = {};
  data.url = "get-logged-in-user";
  data.method = "GET";
  data.loading = "gettingsettings";
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setUser", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const clearLoginData = function({ commit }) {
  commit("setUser", {});
  commit("setToken", null);
  commit("setLoginToken", null);
  commit("clearQueuedActions");
};

export const resend2faEmail = function({ dispatch, state, commit }) {
  const data = {};
  data.url = "resend-2fa-email";
  data.method = "POST";
  data.loading = "validatingcode";
  data.data = { user: state.user };
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      if (response.data.user) {
        commit("setUser", response.data.user);
      }
      if (response.data.token) {
        commit("setToken", response.data.token);
      }
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const gebruikerAanpassen = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "edit-user-from-settings/" + payload.id;
  data.method = "POST";
  data.loading = "gebruikerAanpassen";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setUser", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const apparatenVerwijderen = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "remove-user-devices";
  data.method = "POST";
  data.loading = "apparatenVerwijderen";
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const deletePicture = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "delete-user-picture";
  data.method = "POST";
  data.loading = "deletePicture";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const editPicture = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "edit-user-picture";
  data.method = "POST";
  data.loading = "editPicture";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setUser", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const setRedirect = function({ dispatch, commit }, payload) {
  if (payload.name !== "inloggen") {
    commit("setRedirect", payload);
  }
};
