import Vue from "vue";
import { DateTime } from "luxon";

const formatDatabaseDate = Vue.mixin({
  methods: {
    formatDatabaseDate(payload) {
      const date = DateTime.fromISO(payload);

      return date
        .setLocale("nl")
        .setZone("Europe/Amsterdam")
        .toLocaleString(DateTime.DATE_FULL);
    },
    formatDatabaseTime(payload) {
      const date = DateTime.fromISO(payload);

      return date
        .setLocale("nl")
        .setZone("Europe/Amsterdam")
        .toLocaleString(DateTime.TIME_24_SIMPLE);
    },
    formatDatePickerDate(payload) {
      const date = DateTime.fromISO(payload.replace(" ", "T"), {
        zone: "utc"
      });
      return date
        .setLocale("nl")
        .setZone("Europe/Amsterdam")
        .toLocaleString(DateTime.DATE_FULL);
    },
    formatDatePickerDateAndTime(payload) {
      if (payload) {
        const date = DateTime.fromISO(payload.replace(" ", "T"), {
          zone: "utc"
        });
        return date
          .setLocale("nl")
          .setZone("Europe/Amsterdam")
          .toLocaleString(DateTime.DATETIME_FULL)
          .slice(0, -5);
      } else {
        return payload;
      }
    },
    formatDataBaseDateToJSDate(payload) {
      if (payload) {
        if (typeof payload === "string") {
          const date = DateTime.fromISO(payload.replace(" ", "T"), {
            zone: "utc"
          });
          return date
            .setLocale("nl")
            .setZone("Europe/Amsterdam")
            .toJSDate();
        }
      }
    }
  }
});

export default formatDatabaseDate;
