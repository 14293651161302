<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}
</style>

<template>
  <div>
    <div class="users-title">Dossiers</div>

    <div class="block table-icons">
      <b-tooltip label="Dossier aanmaken" position="is-top">
        <span class="pointer table-icon" @click="dossierAanmaken()">
          <b-icon icon="plus"></b-icon>
        </span>
      </b-tooltip>
    </div>

    <br />
    <br />
    <div>
      <b-loading v-model="ajaxloading.gettingDossiers" />
      <b-tabs v-model="selectedTab">
        <b-tab-item label="Dossiers in behandeling">
          <b-field label="">
            <b-input
              v-model="zoekwaarde"
              @input="zoekDossiers()"
              placeholder="Zoeken..."
            ></b-input>
          </b-field>

          <div
            class="columns"
            v-for="outerLoop in Math.ceil(
              filteredDossiers.length / maxItemsPerRow
            )"
            :key="outerLoop"
          >
            <div
              class="column is-2"
              v-for="item in calculatedMax(outerLoop)"
              :key="item"
            >
              <div
                v-if="
                  filteredDossiers[
                    item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                  ]
                "
                class="card pointer"
                @click="
                  dossierClicked(
                    filteredDossiers[
                      item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                    ].slug
                  )
                "
              >
                <div class="card-image">
                  <figure class="image is-square">
                    <img
                      v-if="
                        filteredDossiers[
                          item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                        ].dossier_foto
                      "
                      :src="
                        env.VUE_APP_API_URL +
                        '/web/get-dossier-picture/' +
                        filteredDossiers[
                          item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                        ].dossier_foto
                      "
                      alt="Profile picture"
                    />
                    <img
                      v-else
                      src="@/assets/dossier.png"
                      alt="Dossier picture"
                    />
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media"></div>
                    <div class="media-content">
                      <div class="content has-text-centered">
                        {{
                          filteredDossiers[
                            item +
                              outerLoop * maxItemsPerRow -
                              maxItemsPerRow -
                              1
                          ].id
                        }}
                        -
                        {{
                          filteredDossiers[
                            item +
                              outerLoop * maxItemsPerRow -
                              maxItemsPerRow -
                              1
                          ].dossier_naam
                        }}
                        <br />
                        <p>
                          {{
                            filteredDossiers[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ].gemeente
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Afgehandelde dossiers">
          <b-field label="">
            <b-input
              v-model="zoekwaarde"
              @input="zoekDossiers()"
              placeholder="Zoeken..."
            ></b-input>
          </b-field>

          <span v-if="selected">
            <b-tooltip
              class="is-pulled-right"
              label="Dossier verwijderen"
              position="is-top"
              v-if="user.rol !== 'eddi'"
            >
              <span class="pointer table-icon" @click="deleteDossier(selected)">
                <b-icon icon="trash"></b-icon>
              </span>
            </b-tooltip>
            <b-tooltip
              class="is-pulled-right"
              label="Dossier bekijken"
              position="is-top"
            >
              <span
                class="pointer table-icon"
                @click="dossierClicked(selected.slug)"
              >
                <b-icon icon="search"></b-icon>
              </span>
            </b-tooltip>
          </span>

          <br />
          <br />

          <b-table
            :data="filteredAfgehandeldeDossiers"
            :bordered="true"
            :striped="true"
            :hoverable="true"
            :paginated="true"
            :per-page="50"
            :pagination-simple="true"
            :pagination-position="'bottom'"
            :pagination-rounded="true"
            :loading="false"
            :focusable="false"
            :mobile-cards="false"
            :selected.sync="selected"
            @click="dossierClicked(selected.slug)"
          >
            <b-table-column
              field="id"
              label="ID"
              width="40"
              numeric
              v-slot="props"
              sortable
            >
              {{ props.row.id }}
            </b-table-column>

            <b-table-column
              sortable
              field="dossier_naam"
              label="Dossier"
              v-slot="props"
            >
              {{ hoofdletter(props.row.dossier_naam) }}
            </b-table-column>

            <b-table-column
              sortable
              field="gemeente"
              label="Gemeente"
              v-slot="props"
            >
              {{ hoofdletter(props.row.gemeente) }}
            </b-table-column>

            <b-table-column
              sortable
              field="status"
              label="Status"
              v-slot="props"
            >
              {{ props.row.status }}
            </b-table-column>
          </b-table>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      selected: null,
      selectedTab: 0,
      maxItemsPerRow: 6,
      zoekwaarde: "",
      databaseDossiers: [],
      env: process.env,
    };
  },
  computed: {
    dossiers() {
      return this.$store.getters["dossiers/dossiers"];
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    filteredDossiers() {
      if (this.zoekwaarde.length > 2) {
        return this.databaseDossiers.filter(
          (a) => a.status === "in behandeling"
        );
      } else {
        return this.dossiers.filter((a) => a.status === "in behandeling");
      }
    },
    filteredAfgehandeldeDossiers() {
      if (this.zoekwaarde.length > 2) {
        return this.databaseDossiers.filter(
          (a) => a.status !== "in behandeling"
        );
      } else {
        return this.dossiers.filter((a) => a.status !== "in behandeling");
      }
    },
    // registraties() {
    //   return this.$store.getters["registraties/registraties"];
    // },
    // huidigeRegistraties() {
    //   return this.registraties.filter(a => a.status !== "afgehandeld");
    // },
    // afgehandeldeRegistraties() {
    //   return this.registraties.filter(a => a.status === "afgehandeld");
    // }
  },
  methods: {
    zoekDossiers() {
      const data = {
        zoekwaarde: this.zoekwaarde,
      };

      if (data.zoekwaarde.length > 2) {
        this.$store
          .dispatch("dossiers/searchDossiers", data)
          .then((response) => {
            this.databaseDossiers = response.data;
          });
      }
    },
    calculatedMax(loop) {
      const max = this.filteredDossiers.length;
      const rows = this.maxItemsPerRow * loop;
      if (max < rows) {
        return max - this.maxItemsPerRow * (loop - 1);
      } else {
        return this.maxItemsPerRow;
      }
    },
    dossierAanmaken() {
      this.$router.push({ name: "dossier-aanmaken" });
    },

    dossierClicked(dossier) {
      this.$router.push({ name: "dossier", params: { dossier: dossier } });
    },
    deleteDossier(dossier) {
      this.$buefy.dialog.confirm({
        title: "Dossier verwijderen",
        message: "Wilt u dossier " + dossier.dossier_naam + " verwijderen?",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () =>
          this.$store.dispatch("dossiers/deleteDossier", dossier).then(() => {
            this.selected = null;
          }),
      });
    },

    // registratieAanpassen() {
    //   this.$router.push({
    //     name: "registratie-aanpassen",
    //     params: { registratie: this.selected.id }
    //   });
    // },
    // registratieVerwijderen() {
    //   this.$buefy.dialog.confirm({
    //     title: "Gebruiker archiveren",
    //     message:
    //       'Weet je zeker dat je registratie "' +
    //       this.selected.id +
    //       '" wil verwijderen?',
    //     confirmText: "Verwijderen",
    //     type: "is-danger",
    //     hasIcon: true,
    //     onConfirm: () =>
    //       this.$store
    //         .dispatch("registraties/registratieVerwijderen", this.selected)
    //         .then(() => {
    //           this.selected = null;
    //         })
    //   });
    // },
    // getAfgehandeldeRegistraties() {
    //   this.$store.dispatch("registraties/getRegistraties");
    // }
  },
  watch: {
    selectedTab: function (val) {
      this.zoekwaarde = "";
      this.databaseDossiers = [];
      if (this.selectedTab === 1) {
        this.$store.dispatch("dossiers/getDossiers");
      }
    },
  },
  mounted() {
    this.$store.dispatch("dossiers/getDossiersInbehandeling");
  },
};
</script>
