<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}
</style>

<template>
  <div>
    <div class="users-title">Logboek</div>

    <br />
    <br />
    <div>
      <b-loading v-model="ajaxloading.getdosieractionlogs" />
      <b-table
        :data="dossierLogs"
        :bordered="true"
        :striped="true"
        :paginated="true"
        :per-page="50"
        :pagination-simple="true"
        :pagination-position="'top'"
        :pagination-rounded="true"
        :loading="false"
        :focusable="false"
        :mobile-cards="false"
        :narrowed="true"
        detailed
      >
        <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column label="Gebruiker" v-slot="props">
          <span v-if="props.row.user">{{ props.row.user.name }}</span>
          <span v-else>Gebruiker onbekend</span>
        </b-table-column>

        <b-table-column label="Timestamp" v-slot="props">
          {{ formatDatePickerDateAndTime(props.row.datum) }}
        </b-table-column>
        <b-table-column label="Actie" v-slot="props">
          {{ props.row.actie }}
        </b-table-column>
        <b-table-column label="Model" v-slot="props">
          {{ props.row.model }}
        </b-table-column>
        <b-table-column label="ModelID" v-slot="props">
          {{ props.row.modelId }}
        </b-table-column>
        <template slot="detail" slot-scope="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <span v-if="props.row.actie === 'CREATE'">
                  <ul>
                    <li
                      v-for="detail of props.row.log_details"
                      :key="detail.id"
                    >
                      {{ detail.modelKey }} => "{{ detail.new }}"
                    </li>
                  </ul>
                </span>
                <span v-if="props.row.actie === 'UPDATE'">
                  <ul>
                    <li
                      v-for="detail of props.row.log_details"
                      :key="detail.id"
                    >
                      {{ detail.modelKey }} =>
                      <span
                        style="text-decoration: line-through"
                        v-if="detail.old"
                        >"{{ detail.old }}"</span
                      >
                      "{{ detail.new }}"
                    </li>
                  </ul>
                </span>
                <p
                  v-if="
                    props.row.actie === 'SHOW' || props.row.actie === 'DELETE'
                  "
                >
                  Geen details
                </p>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {
    dossierLogs() {
      if (this.$store.getters["dossiers/dossierLogs"]) {
        const logs = this.$store.getters["dossiers/dossierLogs"];
        logs.sort((a, b) => {
          return b.id - a.id;
        });
        return logs;
      } else {
        return [];
      }
    },
  },
  methods: {},
  mounted() {
    this.$store.dispatch("dossiers/getLogs", this.$route.params.dossier);
  },
};
</script>
