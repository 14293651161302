<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}
</style>

<template>
  <div>
    <div>
      <div class="box container">
        <div class="users-title">Gebruiker {{ gebruiker.name }} aanpassen</div>
        <br />
        <br />
        <br />
        <form @submit.prevent="gebruikerAanpassen()">
          <b-field
            label="Voornaam"
            :type="{ 'is-danger': validation.voornaam }"
            :message="{
              [validation.voornaam]: validation.voornaam
            }"
          >
            <b-input rounded placeholder="Voornaam" v-model="form.voornaam">
            </b-input>
          </b-field>
          <b-field
            label="Achternaam"
            :type="{ 'is-danger': validation.achternaam }"
            :message="{
              [validation.achternaam]: validation.achternaam
            }"
          >
            <b-input rounded placeholder="Achternaam" v-model="form.achternaam">
            </b-input>
          </b-field>

          <b-field
            label="Email"
            :type="{ 'is-danger': validation.email }"
            :message="{
              [validation.email]: validation.email
            }"
          >
            <b-input rounded placeholder="Email" v-model="form.email">
            </b-input>
          </b-field>

          <b-field
            label="Telefoonnummer"
            :type="{ 'is-danger': validation.telefoonnummer }"
            :message="{
              [validation.telefoonnummer]: validation.telefoonnummer
            }"
          >
            <b-input
              rounded
              placeholder="Telefoonnummer"
              v-model="form.telefoonnummer"
            >
            </b-input>
          </b-field>

          <div class="field">
            <b-checkbox v-model="form.password_change">
              Wachtwoord veranderen
            </b-checkbox>
          </div>

          <span v-if="form.password_change">
            <b-field
              label="Huidig password"
              :type="{ 'is-danger': validation.huidig_password }"
              :message="{
                [validation.huidig_password]: validation.huidig_password
              }"
            >
              <b-input
                type="password"
                rounded
                placeholder="Huidig password"
                password-reveal
                v-model="form.huidig_password"
              >
              </b-input>
            </b-field>

            <b-field
              label="Nieuw password"
              :type="{ 'is-danger': validation.nieuw_password }"
              :message="{
                [validation.nieuw_password]: validation.nieuw_password
              }"
            >
              <b-input
                type="password"
                rounded
                placeholder="Nieuw password"
                password-reveal
                v-model="form.nieuw_password"
              >
              </b-input>
            </b-field>

            <b-field
              label="Nieuw password herhalen"
              :type="{ 'is-danger': validation.nieuw_password_confirmation }"
              :message="{
                [validation.nieuw_password_confirmation]:
                  validation.nieuw_password_confirmation
              }"
            >
              <b-input
                type="password"
                rounded
                placeholder="Nieuw password herhalen"
                password-reveal
                v-model="form.nieuw_password_confirmation"
              >
              </b-input>
            </b-field>
          </span>

          <b-button
            native-type="submit"
            :loading="ajaxloading.gebruikerAanpassen"
            type="is-primary is-rounded"
            >Gebruiker aanpassen</b-button
          >&nbsp;

          <b-button type="is-rounded" @click="$router.go(-1)"
            >Annuleren</b-button
          >
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        voornaam: "",
        achternaam: "",
        id: "",
        email: "",
        telefoonnummer: ""
      }
    };
  },
  computed: {
    gebruiker() {
      return this.$store.getters["auth/user"];
    }
  },
  methods: {
    gebruikerAanpassen() {
      this.$store.dispatch("auth/gebruikerAanpassen", this.form).then(() => {
        this.$store.dispatch("createFlashBanner", {
          message: "Uw instellingen zijn aangepast.",
          timeout: 3
        });
        this.$router.push({ name: "app" });
      });
    }
  },
  watch: {
    gebruiker: function(val) {
      this.form.voornaam = this.gebruiker.voornaam;
      this.form.achternaam = this.gebruiker.achternaam;
      this.form.email = this.gebruiker.email;
      this.form.telefoonnummer = this.gebruiker.telefoonnummer;
      this.form.id = this.gebruiker.id;
    }
  },
  mounted() {
    this.form.voornaam = this.gebruiker.voornaam;
    this.form.achternaam = this.gebruiker.achternaam;
    this.form.email = this.gebruiker.email;
    this.form.telefoonnummer = this.gebruiker.telefoonnummer;
    this.form.id = this.gebruiker.id;
  }
};
</script>

<style scoped>
.centered-box {
  min-width: 800px;
}
</style>
