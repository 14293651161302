<template>
  <div>
    <div class="container">
      <br />
      <div class="box">
        <div class="title is-4">Welkom terug {{ user.name }}</div>
        U heeft voor het laatst ingelogd op:
        <span v-if="dashboardStats.last_login !== 'niet eerder ingelogd'">{{
          formatDatePickerDateAndTime(dashboardStats.last_login)
        }}</span
        ><span v-else>Niet eerder ingelogd.</span>
      </div>

      <span v-if="user.rol">
        <vizierDashBoard v-if="user.rol !== 'eddi'" />

        <eddiDashBoard v-else />
      </span>

      <!-- <b-field label="Registraties">
        <table style="width:80%">
          <tr>
            <td>Registraties in behandeling</td>
            <td>{{ registratieStats.registraties_in_behandeling }}</td>
          </tr>
          <tr>
            <td>Afgehandelde registraties</td>
            <td>{{ registratieStats.afgehandelde_registraties }}</td>
          </tr>
          <tr>
            <td>Totale registraties</td>
            <td>{{ registratieStats.totale_registraties }}</td>
          </tr>
        </table>
      </b-field> -->
    </div>
  </div>
</template>

<script>
import eddiDashBoard from "./EddiDashboard.vue";
import vizierDashBoard from "./VizierDashboard.vue";

export default {
  components: {
    eddiDashBoard,
    vizierDashBoard
  },
  props: [],
  data() {
    return {};
  },
  computed: {
    registratieStats() {
      return this.$store.getters["rapportages/registratieStats"];
    },
    dashboardStats() {
      return this.$store.getters["dashboardStats"];
    },
    user() {
      return this.$store.getters["auth/user"];
    }
  },
  methods: {
    tileClicked(routeName) {
      this.$router.push({ name: routeName });
    }
  },
  mounted() {
    this.$store.dispatch("getDashboardStats");
  }
};
</script>
