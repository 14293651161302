<style scoped>
.column {
  padding-top: 0px;
  padding-bottom: 0px;
}

.vizier-rol {
  color: red;
}
</style>

<template>
  <div>
    <b-loading v-model="ajaxloading.gettingTicket" />
    <button
      class="button is-primary is-rounded is-small"
      @click="$router.push({ name: 'tickets-index' })"
    >
      Terug naar tickets
    </button>
    <br />
    <br />
    <div class="columns">
      <div class="column">Ticket aangemaakt door:</div>
      <div class="column" v-if="ticket.user">
        <a
          @click="
            $router.push({ name: 'eddi', params: { eddi: ticket.user.id } })
          "
          >{{ ticket.user.name }}</a
        >
      </div>
    </div>

    <div class="columns" v-if="ticket.dossier && ticket.dossier.id">
      <div class="column">Ticket voor dossier:</div>
      <div class="column">
        <a
          @click="
            $router.push({
              name: 'dossier',
              params: { dossier: ticket.dossier.slug }
            })
          "
        >
          {{ ticket.dossier.id + "-" + ticket.dossier.dossier_naam }}</a
        >
      </div>
    </div>

    <br />

    <div class="columns" v-if="ticket.dossier && ticket.dossier.id">
      <div class="column">Status ticket:</div>
      <div class="column">
        {{ ticket.status }}
      </div>
    </div>

    <br />

    <b-button
      class="is-primary"
      icon-left="reply"
      @click="reageren = true"
      v-if="reageren === false"
    >
      Reageren
    </b-button>
    <b-button
      class="is-primary"
      icon-left="times"
      @click="reageren = false"
      v-else
    >
      Annuleren
    </b-button>
    <b-button
      class="is-warning is-pulled-right"
      icon-left="archive"
      v-if="ticket.status === 'actief'"
      @click="archiveTicket()"
    >
      Ticket Archiveren
    </b-button>

    <b-button
      class="is-warning is-pulled-right"
      icon-left="archive"
      v-if="ticket.status !== 'actief'"
      @click="unArchiveTicket()"
    >
      Ticket actief maken
    </b-button>

    <br />
    <br />

    <span v-if="reageren === false">
      <div class="box" v-for="comment of comments" :key="comment.id">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64">
              <img
                v-if="comment.user.picture"
                :src="
                  env.VUE_APP_API_URL +
                    '/web/get-ticket-picture/' +
                    comment.user.picture
                "
              />
              <img v-else src="@/assets/128x128.png" />
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                <strong v-if="comment.user.rol === 'eddi'"
                  ><a
                    @click.stop="
                      $router.push({
                        name: 'eddi',
                        params: { eddi: comment.user.id }
                      })
                    "
                    >{{ hoofdletter(comment.user.name) }}</a
                  ></strong
                >
                <span v-else>
                  <strong>{{ hoofdletter(comment.user.name) }}</strong
                  ><span v-if="comment.user.rol === 'vizier'"
                    ><small class="vizier-rol"> Vizier</small></span
                  >
                  <span v-if="comment.user.rol === 'admin'"
                    ><small class="vizier-rol"> Admin</small></span
                  >
                </span>
                op
                <small
                  >{{ formatDatabaseDate(comment.created_at) }} om
                  {{ formatDatabaseTime(comment.created_at) }}</small
                >
                <br />
                <span v-html="comment.ticket"></span>
              </p>
            </div>
          </div>
        </article>
      </div>

      <div class="box">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64">
              <img
                v-if="ticket.user && ticket.user.picture"
                :src="
                  env.VUE_APP_API_URL +
                    '/web/get-ticket-picture/' +
                    ticket.user.picture
                "
              />
              <img v-else src="@/assets/128x128.png" />
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                <span v-if="ticket.user">
                  <strong v-if="ticket.user.rol === 'eddi'"
                    ><a
                      @click.stop="
                        $router.push({
                          name: 'eddi',
                          params: { eddi: ticket.user.id }
                        })
                      "
                      >{{ hoofdletter(ticket.user.name) }}</a
                    ></strong
                  >
                  <span v-else>
                    <strong>{{ hoofdletter(ticket.user.name) }}</strong
                    ><span v-if="ticket.user.rol === 'vizier'"
                      ><small class="vizier-rol"> Vizier</small></span
                    >
                    <span v-if="ticket.user.rol === 'admin'"
                      ><small class="vizier-rol"> Admin</small></span
                    >
                  </span>
                </span>
                op
                <small
                  >{{ formatDatabaseDate(ticket.created_at) }} om
                  {{ formatDatabaseTime(ticket.created_at) }}</small
                >
                <br />
                <span v-html="ticket.ticket"></span>
              </p>
            </div>
          </div>
        </article>
      </div>
      <br />
    </span>
    <span v-else>
      <formEditor modelKey="comment" title=" " :model="form" />
      <br />
      <button
        :class="{ 'is-loading': ajaxloading.createComment }"
        class="button is-primary is-rounded"
        @click="createComment()"
      >
        Reageren
      </button>
      <button class="button is-rounded" @click="reageren = false">
        Annuleren
      </button>
    </span>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {},
      reageren: false,
      env: process.env
    };
  },
  computed: {
    ticket() {
      return this.$store.getters["tickets/ticket"];
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    comments() {
      if (this.ticket.comments) {
        const comments = this.ticket.comments;
        comments.reverse();
        return comments;
      } else {
        return [];
      }
    }
  },
  methods: {
    createComment() {
      this.form.ticket = this.ticket;

      this.$store.dispatch("tickets/createComment", this.form).then(() => {
        this.form = {};
        this.reageren = false;
      });
    },
    archiveTicket() {
      const data = {};
      data.url = "ticket-archiveren/" + this.ticket.id;
      data.method = "POST";
      data.loading = "ticketeddit";

      this.$buefy.dialog.confirm({
        title: "Ticket archiveren",
        message: "Wilt u deze ticket archiveren?",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () =>
          this.$store.dispatch("axiosWeb", data).then(() => {
            this.ticket.status = "gearchiveerd";
            this.$router.replace({ name: "tickets-index" });
          })
      });
    },
    unArchiveTicket() {
      const data = {};
      data.url = "ticket-activeren/" + this.ticket.id;
      data.method = "POST";
      data.loading = "ticketeddit";

      this.$buefy.dialog.confirm({
        title: "Ticket activeren",
        message: "Wilt u deze ticket uit het archief halen?",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () =>
          this.$store.dispatch("axiosWeb", data).then(() => {
            this.ticket.status = "actief";
          })
      });
    }
  },
  mounted() {
    this.$store.dispatch(
      "tickets/getTicket",
      this.$router.currentRoute.params.ticket
    );
  }
};
</script>
