<style scoped>
.column {
  padding-top: 0px;
  padding-bottom: 0px;
}

.no-padding {
  padding-top: 0px;
  padding-bottom: 0px;
}

.reply {
  margin-left: 50px;
}

.table-icons {
  float: right;
}
</style>

<template>
  <div>
    <div>
      <button
        class="button is-primary is-rounded is-small"
        @click="$router.push({ name: 'eddi-index' })"
      >
        Terug naar coaches
      </button>
    </div>
    <br />

    <b-tabs v-model="selectedTab" expanded>
      <b-loading v-model="ajaxloading.gettingeddi" />
      <b-tab-item label="Stamgegevens">
        <br />
        <div class="columns is-mobile">
          <div class="column">
            <span class="title is-5">{{ item.name }}</span> &nbsp;
          </div>
          <div class="column">
            <!-- <b-icon
              class="pointer is-pulled-right"
              icon="trash"
              @click.native="deleteProduct()"
            /> -->
            <div class="block table-icons">
              <b-tooltip label="Coach foto aanpassen" position="is-top">
                <span
                  class="pointer"
                  @click="
                    $router.push({
                      name: 'eddi-foto-aanpassen',
                      params: { eddi: item.id },
                    })
                  "
                >
                  <b-icon class="pointer" icon="camera" />
                </span>
              </b-tooltip>

              <b-tooltip
                label="Coach archiveren"
                position="is-top"
                v-if="item.status !== 'gearchiveerd'"
              >
                <span class="pointer" @click="eddiArchiveren()">
                  <b-icon class="pointer" icon="archive" />
                </span>
              </b-tooltip>

              <b-tooltip
                label="Coach activeren"
                position="is-top"
                v-if="
                  item.status === 'vergrendeld' ||
                  item.status === 'gearchiveerd'
                "
              >
                <span class="pointer" @click="eddiActiveren()">
                  <b-icon class="pointer" icon="user" />
                </span>
              </b-tooltip>

              <b-tooltip
                label="Coach deactiveren"
                position="is-top"
                v-if="item.status === 'actief'"
              >
                <span class="pointer" @click="eddiDeactiveren()">
                  <b-icon class="pointer" icon="user-slash" />
                </span>
              </b-tooltip>

              <b-tooltip label="Uitnodiging opnieuw sturen" position="is-top">
                <span class="pointer" @click="uitnodigingOpnieuwSturen()">
                  <b-icon class="pointer" icon="envelope" />
                </span>
              </b-tooltip>

              <b-tooltip label="Dossier aanpassen" position="is-top">
                <span class="pointer" @click="aanpassen()">
                  <b-icon class="pointer" icon="edit" />
                </span>
              </b-tooltip>
            </div>
          </div>
        </div>

        <span v-if="item">
          <showModel :model="item" modelKey="voornaam" />
          <showModel :model="item" modelKey="achternaam" />
          <showModel :model="item" modelKey="telefoonnummer" />
          <showModel :model="item" modelKey="email" />
          <br />
          <showModel :model="item" modelKey="status" />
          <br />
          <div class="columns is-mobile">
            <div class="column">Laatst ingelogd:</div>
            <div class="column" v-if="item.logins && item.logins.length">
              {{ formatDatePickerDateAndTime(getMaxRow(item.logins).datum) }}
            </div>
            <div class="column" v-else>Nog nooit ingelogd</div>
          </div>
        </span>
      </b-tab-item>

      <b-tab-item label="Dossiers">
        <dossiers :eddi="item" />
      </b-tab-item>

      <b-tab-item label="Tickets">
        <tickets :eddi="item" />
      </b-tab-item>

      <b-tab-item label="Logboek">
        <logs :eddi="item" />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import logs from "./Logs.vue";
import dossiers from "./EddiDossiers.vue";
import tickets from "./EddiTickets.vue";
export default {
  components: {
    logs,
    dossiers,
    tickets,
  },
  props: [],
  data() {
    return {
      selectedTab: 0,
      form: {},
      selectedRapportage: "",
    };
  },
  computed: {
    item() {
      return this.$store.getters["eddis/eddi"];
    },
  },
  methods: {
    aanpassen() {
      this.$router.push({
        name: "eddi-aanpassen",
        params: { eddi: this.item.id },
      });
    },
    dossierFotoAanpassen() {
      this.$router.push({
        name: "dossier-foto-aanpassen",
        params: { dossier: this.dossier.slug },
      });
    },
    uitnodigingOpnieuwSturen() {
      const data = {};
      data.url = "eddi-uitnodiging-opnieuw-sturen/" + this.item.id;
      data.method = "POST";
      data.loading = "eddiedit";

      this.$buefy.dialog.confirm({
        title: "Uitnodiging sturen",
        message:
          "Wilt u de uitnodiging voor " +
          this.item.name +
          " opnieuw versturen?",
        type: "is-info",
        hasIcon: true,
        onConfirm: () => this.$store.dispatch("axiosWeb", data),
      });
    },
    eddiDeactiveren() {
      const data = {};
      data.url = "eddi-deactiveren/" + this.item.id;
      data.method = "POST";
      data.loading = "eddiedit";

      this.$buefy.dialog.confirm({
        title: "Eddi deactiveren",
        message: "Wilt u het account van " + this.item.name + " deactiveren?",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () =>
          this.$store.dispatch("axiosWeb", data).then(() => {
            this.item.status = "vergrendeld";
          }),
      });
    },
    eddiActiveren() {
      const data = {};
      data.url = "eddi-activeren/" + this.item.id;
      data.method = "POST";
      data.loading = "eddiedit";

      this.$buefy.dialog.confirm({
        title: "Eddi activeren",
        message:
          "Wilt u het account van " + this.item.name + " opnieuw activeren?",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () =>
          this.$store.dispatch("axiosWeb", data).then(() => {
            this.item.status = "actief";
          }),
      });
    },
    eddiArchiveren() {
      const data = {};
      data.url = "eddi-archiveren/" + this.item.id;
      data.method = "POST";
      data.loading = "eddiedit";

      this.$buefy.dialog.confirm({
        title: "Eddi activeren",
        message:
          "Wilt u het account van " +
          this.item.name +
          " deactiveren en archiveren?",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () =>
          this.$store.dispatch("axiosWeb", data).then(() => {
            this.item.status = "gearchiveerd";
          }),
      });
    },
  },
  mounted() {
    this.$store.dispatch("eddis/getEddi", this.$route.params.eddi);
  },
};
</script>
