<template>
  <div>
    <button
      class="button is-primary is-rounded"
      v-if="user.rol === 'eddi'"
      @click="$router.push({ name: 'ticket-aanmaken' })"
    >
      Nieuwe ticket openen
    </button>

    <br />
    <br />
    <b-tabs v-model="selectedTab" expanded>
      <b-loading v-model="ajaxloading.gettingTickets" />
      <b-tab-item label="Openstaande tickets">
        <article
          @click="showTicket(ticket)"
          class="media pointer box"
          v-for="ticket of actieveTickets"
          :key="ticket.id"
        >
          <figure class="media-left">
            <p class="image is-64x64">
              <img
                v-if="ticket.user.picture"
                :src="
                  env.VUE_APP_API_URL +
                    '/web/get-ticket-picture/' +
                    ticket.user.picture
                "
              />
              <img v-else src="@/assets/128x128.png" />
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                <strong v-if="ticket.user.rol === 'eddi'"
                  ><a
                    @click.stop="
                      $router.push({
                        name: 'eddi',
                        params: { eddi: ticket.user.id }
                      })
                    "
                    >{{ hoofdletter(ticket.user.name) }}</a
                  ></strong
                >
                <strong v-else>{{ hoofdletter(ticket.user.name) }}</strong>
                op
                <small
                  >{{ formatDatabaseDate(ticket.created_at) }} om
                  {{ formatDatabaseTime(ticket.created_at) }}</small
                >
                <span v-if="ticket.dossier && ticket.dossier.dossier_naam">
                  <br />
                  <small
                    ><a
                      @click.stop="
                        $router.push({
                          name: 'dossier',
                          params: { dossier: ticket.dossier.slug }
                        })
                      "
                      >{{
                        ticket.dossier.id + "-" + ticket.dossier.dossier_naam
                      }}</a
                    ></small
                  >
                </span>
                <br />
                <span v-html="ticket.ticket"></span>
              </p>
            </div>
            <nav class="level is-mobile">
              <div class="level-left">
                <a class="level-item" @click.stop="showTicket(ticket)">
                  <b-icon icon="reply"></b-icon>Reageren
                </a>
                <a class="level-item" @click.stop="archiveTicket(ticket)">
                  <b-icon icon="archive"></b-icon>Archiveren
                </a>
              </div>
            </nav>
          </div>
        </article>
      </b-tab-item>
      <b-tab-item label="Afgehandelde tickets">
        <span v-if="selected">
          <b-tooltip
            class="is-pulled-right"
            label="Ticket bekijken"
            position="is-top"
          >
            <span class="pointer table-icon" @click="showTicket(selected)">
              <b-icon icon="search"></b-icon>
            </span>
          </b-tooltip>
        </span>
        <br />
        <br />
        <b-table
          :data="afgehandeldeTickets"
          :bordered="true"
          :striped="true"
          :hoverable="true"
          :paginated="true"
          :per-page="50"
          :pagination-simple="true"
          :pagination-position="'bottom'"
          :pagination-rounded="true"
          :loading="false"
          :focusable="false"
          :mobile-cards="false"
          :selected.sync="selected"
          @dblclick="showTicket(selected)"
        >
          <b-table-column
            field="id"
            label="ID"
            width="40"
            numeric
            v-slot="props"
            sortable
          >
            {{ props.row.id }}
          </b-table-column>

          <b-table-column
            sortable
            field="dossier_naam"
            label="Gebruiker"
            v-slot="props"
          >
            {{ hoofdletter(props.row.user.name) }}
          </b-table-column>

          <b-table-column sortable field="datum" label="Datum" v-slot="props">
            {{ formatDatePickerDateAndTime(props.row.created_at) }}
          </b-table-column>

          <b-table-column sortable field="status" label="Status" v-slot="props">
            {{ props.row.status }}
          </b-table-column>
        </b-table>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      selectedTab: 0,
      selected: null,
      env: process.env
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    tickets() {
      return this.$store.getters["tickets/tickets"];
    },
    actieveTickets() {
      return this.tickets.filter(a => a.status === "actief");
    },
    afgehandeldeTickets() {
      return this.tickets.filter(a => a.status !== "actief");
    }
  },
  methods: {
    showTicket(ticket) {
      this.$router.push({ name: "ticket", params: { ticket: ticket.id } });
    },
    archiveTicket(ticket) {
      const data = {};
      data.url = "ticket-archiveren/" + ticket.id;
      data.method = "POST";
      data.loading = "ticketeddit";

      this.$buefy.dialog.confirm({
        title: "Ticket archiveren",
        message: "Wilt u deze ticket archiveren?",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () =>
          this.$store.dispatch("axiosWeb", data).then(() => {
            ticket.status = "gearchiveerd";
          })
      });
    }
  },
  watch: {
    selectedTab() {
      if (this.selectedTab === 1) {
        this.$store.dispatch("tickets/getTickets");
      }
    }
  },
  mounted() {
    this.$store.dispatch("tickets/getActiveTickets");
  }
};
</script>
