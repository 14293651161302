<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}
</style>

<template>
  <div>
    <div>
      <div class="box container">
        <div class="users-title">
          Ingelogde apparaten voor {{ gebruiker.name }} verwijderen
        </div>
        <br />
        <br />
        <br />
        <form @submit.prevent="apparatenVerwijderen()">
          <b-button
            native-type="submit"
            :loading="ajaxloading.apparatenVerwijderen"
            type="is-primary is-rounded"
            >Apparaten verwijderen</b-button
          >&nbsp;

          <b-button type="is-rounded" @click="$router.go(-1)"
            >Annuleren</b-button
          >
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        naam: "",
        id: ""
      }
    };
  },
  computed: {
    gebruiker() {
      return this.$store.getters["auth/user"];
    }
  },
  methods: {
    apparatenVerwijderen() {
      this.$store.dispatch("auth/apparatenVerwijderen", this.form).then(() => {
        this.$store.dispatch("createFlashBanner", {
          message: "Uw ingelogde apparaten zijn verwijderd.",
          timeout: 3
        });
        this.$router.push({ name: "app" });
      });
    }
  }
};
</script>

<style scoped>
.centered-box {
  min-width: 800px;
}
</style>
