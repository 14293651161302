export default () => ({
  loading: [],
  valerrors: {},
  flashBanner: null,
  deleteModal: false,
  dashboardStats: {},
  vizierdashboard: {
    open_dossiers: [],
    ingelogde_eddis: [],
    open_tickets: [],
    niet_beantwoorde_tickets: []
  },
  eddiDashboard: {
    open_dossiers: [],
    open_tickets: [],
    beantwoorde_tickets: []
  }
});
