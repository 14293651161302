<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.datePicker {
  margin-bottom: 12px;
}
</style>

<template>
  <div>
    <div v-if="$route.name === 'dossier-aanmaken'" class="users-title">
      Dossier toevoegen
    </div>
    <div v-else class="users-title">
      Dossier aanpassen
    </div>

    <br />

    <form @submit.prevent="submit()">
      <br />
      <div class="title has-text-centered">Gegevens dossier</div>
      <div class="columns">
        <div class="column">
          <b-field
            label="Naam van dossier"
            :type="{ 'is-danger': validation.dossier_naam }"
            :message="{ [validation.dossier_naam]: validation.dossier_naam }"
          >
            <b-input
              size="is-small"
              v-model="form.dossier_naam"
              rounded
              placeholder="Naam van dossier"
              id="dossier_naam"
            ></b-input>
          </b-field>
        </div>
      </div>
      <hr />
      <div class="title has-text-centered">Gegevens melder</div>
      <div class="columns">
        <div class="column is-6">
          <formDatepicker
            class="datePicker"
            :model="form"
            title="Datum van melding"
            modelKey="datum_melding"
            placeholder="Selecteer datum van melding"
            size="is-small"
          />
          <!-- <b-field
            id="datum_melding"
            label="Selecteer datum van melding"
            :type="{ 'is-danger': validation.datum_melding }"
            :message="{
              [validation.datum_melding]: validation.datum_melding
            }"
          >
            <b-datepicker
              size="is-small"
              v-model="form.datum_melding"
              locale="nl-NL"
              rounded
              placeholder="Selecteer datum van melding"
              icon="calendar"
              :first-day-of-week="1"
            >
            </b-datepicker>
          </b-field> -->

          <b-field
            label="Bron melding"
            :type="{ 'is-danger': validation.bron }"
            :message="{ [validation.bron]: validation.bron }"
            id="bron"
          >
            <b-select
              size="is-small"
              v-if="settings.dossiers"
              placeholder="Selecteer een bron"
              v-model="form.bron"
              rounded
              expanded
            >
              <option
                v-for="bron of settings.dossiers.bron"
                :key="bron"
                :value="bron"
                >{{ hoofdletter(bron) }}</option
              >
            </b-select>
          </b-field>

          <b-field
            v-if="form.bron === 'anders'"
            :type="{ 'is-danger': validation.bron_anders }"
            :message="{ [validation.bron_anders]: validation.bron_anders }"
          >
            <b-input
              size="is-small"
              v-model="form.bron_anders"
              rounded
              placeholder="Omschrijving bron"
              id="bron_anders"
            ></b-input>
          </b-field>

          <b-field
            label="Gemeente van melder"
            :type="{ 'is-danger': validation.gemeente_melder }"
            :message="{
              [validation.gemeente_melder]: validation.gemeente_melder
            }"
            id="gemeente_melder"
          >
            <b-select
              size="is-small"
              v-if="settings.dossiers"
              placeholder="Selecteer de gemeente van de melder"
              v-model="form.gemeente_melder"
              rounded
              expanded
            >
              <option
                v-for="bron of settings.dossiers.gemeente_melder"
                :key="bron"
                :value="bron"
                >{{ hoofdletter(bron) }}</option
              >
            </b-select>
          </b-field>

          <section id="meldingvoor" v-if="settings.dossiers">
            <div
              class="field"
              v-for="melding of settings.dossiers.melder"
              :key="melding"
            >
              <b-radio
                v-model="form.meldingvoor"
                size="is-small"
                :native-value="melding"
              >
                {{ hoofdletter(melding) }}
              </b-radio>
            </div>
          </section>
          <p v-if="validation.meldingvoor" class="help is-danger">
            {{ validation.meldingvoor[0] }}
          </p>
        </div>

        <div class="column is-6">
          <b-field
            id="geslacht"
            label="Geslacht"
            :type="{ 'is-danger': validation.geslacht }"
            :message="{ [validation.geslacht]: validation.geslacht }"
          >
            <b-select
              size="is-small"
              v-if="settings.dossiers"
              placeholder="Selecteer een geslacht"
              v-model="form.geslacht"
              rounded
              expanded
            >
              <option
                v-for="geslacht of settings.dossiers.geslacht"
                :key="geslacht"
                :value="geslacht"
                >{{ hoofdletter(geslacht) }}</option
              >
            </b-select>
          </b-field>
          <b-field
            label="Naam"
            :type="{ 'is-danger': validation.naam }"
            :message="{ [validation.naam]: validation.naam }"
          >
            <b-input
              size="is-small"
              v-model="form.naam"
              rounded
              placeholder="Naam van melder"
              id="naam"
            ></b-input>
          </b-field>

          <b-field
            id="telefoonnummer"
            size="is-small"
            label="Telefoonnummer"
            :type="{ 'is-danger': validation.telefoonnummer }"
            :message="{
              [validation.telefoonnummer]: validation.telefoonnummer
            }"
          >
            <b-input
              size="is-small"
              v-model="form.telefoonnummer"
              rounded
              placeholder="Telefoonnummer van melder"
            ></b-input>
          </b-field>

          <b-field
            id="email"
            label="Email"
            :type="{ 'is-danger': validation.email }"
            :message="{ [validation.email]: validation.email }"
          >
            <b-input
              size="is-small"
              v-model="form.email"
              rounded
              placeholder="Emailadres"
            ></b-input>
          </b-field>

          <b-field
            id="leeftijd"
            label="Leeftijd"
            :type="{ 'is-danger': validation.leeftijd }"
            :message="{ [validation.leeftijd]: validation.leeftijd }"
          >
            <b-input
              size="is-small"
              v-model="form.leeftijd"
              rounded
              placeholder="Leeftijd in jaren"
            ></b-input>
          </b-field>
        </div>
      </div>

      <hr />
      <div class="title has-text-centered">Gegevens Incident</div>

      <div class="columns">
        <div class="column">
          <b-field
            id="type_incident"
            label="Type Incident"
            :type="{ 'is-danger': validation.type_incident }"
            :message="{ [validation.type_incident]: validation.type_incident }"
          >
            <b-select
              size="is-small"
              v-if="settings.dossiers"
              placeholder="Selecteer het type incident"
              v-model="form.type_incident"
              rounded
              expanded
            >
              <option
                v-for="type_incident of settings.dossiers.type_incident"
                :key="type_incident"
                :value="type_incident"
                >{{ hoofdletter(type_incident) }}</option
              >
            </b-select>
          </b-field>

          <b-field
            id="grondslag"
            label="Grondslag discriminatie"
            :type="{ 'is-danger': validation.grondslag }"
            :message="{ [validation.grondslag]: validation.grondslag }"
          >
            <b-select
              size="is-small"
              v-if="settings.dossiers"
              placeholder="Selecteer de gronsdslag van de discriminatei"
              v-model="form.grondslag"
              rounded
              expanded
            >
              <option
                v-for="grondslag of settings.dossiers.grondslag"
                :key="grondslag"
                :value="grondslag"
                >{{ hoofdletter(grondslag) }}</option
              >
            </b-select>
          </b-field>

          <b-field
            v-if="form.grondslag === 'anders'"
            :type="{ 'is-danger': validation.grondslag_anders }"
            :message="{
              [validation.grondslag_anders]: validation.grondslag_anders
            }"
          >
            <b-input
              size="is-small"
              v-model="form.grondslag_anders"
              rounded
              placeholder="Omschrijving grondslag van de discriminatie"
              id="grondslag_anders"
            ></b-input>
          </b-field>

          <b-field
            id="aard"
            label="Aard discriminatie"
            :type="{ 'is-danger': validation.aard }"
            :message="{ [validation.aard]: validation.aard }"
          >
            <b-select
              size="is-small"
              v-if="settings.dossiers"
              placeholder="Selecteer de aard van de discriminatie"
              v-model="form.aard"
              rounded
              expanded
            >
              <option
                v-for="aard of settings.dossiers.aard"
                :key="aard"
                :value="aard"
                >{{ hoofdletter(aard) }}</option
              >
            </b-select>
          </b-field>

          <b-field
            v-if="form.aard === 'anders'"
            :type="{ 'is-danger': validation.aard_anders }"
            :message="{
              [validation.aard_anders]: validation.aard_anders
            }"
          >
            <b-input
              size="is-small"
              v-model="form.aard_anders"
              rounded
              placeholder="Omschrijving aard van de discriminatie"
              id="aard_anders"
            ></b-input>
          </b-field>

          <b-field
            id="omschrijving_incident"
            label="Omschrijving van het incident"
            :type="{ 'is-danger': validation.omschrijving_incident }"
            :message="{
              [validation.omschrijving_incident]:
                validation.omschrijving_incident
            }"
          >
            <b-input
              v-model="form.omschrijving_incident"
              type="textarea"
              maxlength="10000"
              placeholder="Omschrijving van het incident"
            ></b-input>
          </b-field>
        </div>
        <div class="column">
          <!-- <b-field
            id="datum_incident"
            label="Selecteer datum van incident"
            :type="{ 'is-danger': validation.datum_incident }"
            :message="{
              [validation.datum_incident]: validation.datum_incident
            }"
          >
            <b-datepicker
              size="is-small"
              v-model="form.datum_incident"
              locale="nl-NL"
              rounded
              placeholder="Selecteer datum van incident"
              icon="calendar"
              :first-day-of-week="1"
            >
            </b-datepicker>
          </b-field> -->

          <formDatepicker
            class="datePicker"
            :model="form"
            title="Datum van incident"
            modelKey="datum_incident"
            placeholder="Selecteer datum van incident"
            size="is-small"
          />

          <b-field
            id="postcode"
            label="Postcode"
            :type="{ 'is-danger': validation.postcode }"
            :message="{ [validation.postcode]: validation.postcode }"
          >
            <b-input
              size="is-small"
              v-model="form.postcode"
              rounded
              placeholder="Postcode"
            ></b-input>
          </b-field>

          <b-field
            id="plaats"
            label="Plaats"
            :type="{ 'is-danger': validation.plaats }"
            :message="{ [validation.plaats]: validation.plaats }"
          >
            <b-input
              size="is-small"
              v-model="form.plaats"
              rounded
              placeholder="Plaats"
            ></b-input>
          </b-field>

          <b-field
            id="terrein"
            label="Terrein"
            :type="{ 'is-danger': validation.terrein }"
            :message="{ [validation.terrein]: validation.terrein }"
          >
            <b-select
              size="is-small"
              v-if="settings.dossiers"
              placeholder="Terrein van het incident"
              v-model="form.terrein"
              rounded
              expanded
            >
              <option
                v-for="terrein of settings.dossiers.terrein"
                :key="terrein"
                :value="terrein"
                >{{ hoofdletter(terrein) }}</option
              >
            </b-select>
          </b-field>

          <b-field
            v-if="form.terrein === 'anders'"
            :type="{ 'is-danger': validation.terrein_anders }"
            :message="{
              [validation.terrein_anders]: validation.terrein_anders
            }"
          >
            <b-input
              size="is-small"
              v-model="form.terrein_anders"
              rounded
              placeholder="Terrein van de discriminatie"
              id="terrein_anders"
            ></b-input>
          </b-field>

          <b-field
            id="gemeente"
            label="Gemeente"
            :type="{ 'is-danger': validation.gemeente }"
            :message="{ [validation.gemeente]: validation.gemeente }"
          >
            <b-autocomplete
              rounded
              size="is-small"
              v-model="form.gemeente"
              :data="filteredGemeentes"
              placeholder="Gemeente"
              clearable
              :keep-first="true"
              :open-on-focus="true"
              @select="option => (selected = option)"
            >
              <template #empty>Gemeente onbekend</template>
            </b-autocomplete>
            <!-- <b-input
              id="gemeente"
              custom-class="backgroundGrey"
              placeholder="Gemeente"
              v-model="form.gemeente"
            ></b-input> -->
          </b-field>
        </div>
      </div>

      <b-field
        id="dader_informatie"
        label="Daderinformatie"
        :type="{ 'is-danger': validation.dader_informatie }"
        :message="{
          [validation.dader_informatie]: validation.dader_informatie
        }"
      >
        <b-input
          v-model="form.dader_informatie"
          type="textarea"
          maxlength="10000"
          placeholder="Aantal daders, wat is er gezegd of gebeurd"
        ></b-input>
      </b-field>

      <b-field
        id="gewenste_maatregelen"
        label="Gewenste maatregelen door slachtoffer"
        :type="{ 'is-danger': validation.gewenste_maatregelen }"
        :message="{
          [validation.gewenste_maatregelen]: validation.gewenste_maatregelen
        }"
      >
        <b-input
          v-model="form.gewenste_maatregelen"
          type="textarea"
          maxlength="10000"
          placeholder="Gewenste maatregelen door slachtoffer"
        ></b-input>
      </b-field>

      <b-field
        id="ervaring"
        label="Ervaring van incident"
        :type="{ 'is-danger': validation.ervaring }"
        :message="{ [validation.ervaring]: validation.ervaring }"
      >
        <b-select
          size="is-small"
          v-if="settings.dossiers"
          placeholder="Selecteer de ervaring van de intimidatie"
          v-model="form.ervaring"
          rounded
          expanded
        >
          <option
            v-for="ervaring of settings.dossiers.ervaring"
            :key="ervaring"
            :value="ervaring"
            >{{ hoofdletter(ervaring) }}</option
          >
        </b-select>
      </b-field>
      <br />

      <hr />

      <div class="columns">
        <div class="column">
          <b-field
            id="contact_vizier"
            label="Contact met medewerker Vizier?"
            :type="{ 'is-danger': validation.contact_vizier }"
            :message="{
              [validation.contact_vizier]: validation.contact_vizier
            }"
          >
            <b-select
              size="is-small"
              v-if="settings.dossiers"
              placeholder="Wilt u contact met een medwerker van Vizier?"
              v-model="form.contact_vizier"
              rounded
              expanded
            >
              <option
                v-for="contact_vizier of settings.dossiers.contact_vizier"
                :key="contact_vizier"
                :value="contact_vizier"
                >{{ hoofdletter(contact_vizier) }}</option
              >
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field
            id="status"
            label="Status"
            :type="{ 'is-danger': validation.status }"
            :message="{ [validation.status]: validation.status }"
          >
            <b-select
              size="is-small"
              v-if="settings.dossiers"
              placeholder="Selecteer het type incident"
              v-model="form.status"
              rounded
              expanded
            >
              <option
                v-for="status of settings.dossiers.statussen"
                :key="status"
                :value="status"
                >{{ hoofdletter(status) }}</option
              >
            </b-select>
          </b-field>
        </div>
      </div>

      <b-field
        v-if="form.contact_vizier === 'ja'"
        id="ticket"
        label="Vraag voor vizier"
        :type="{ 'is-danger': validation.ticket }"
        :message="{
          [validation.ticket]: validation.ticket
        }"
      >
        <b-input
          v-model="form.ticket"
          type="textarea"
          maxlength="10000"
          placeholder="Vraag voor vizier"
        ></b-input>
      </b-field>
      <br />

      <b-button
        native-type="submit"
        :loading="ajaxloading.dossieraanmaken"
        type="is-primary is-rounded"
        ><span v-if="$router.currentRoute.name === 'dossier-aanpassen'"
          >Dossier aanpassen</span
        ><span v-else>Dossier aanmaken</span></b-button
      >

      <b-button type="is-rounded" @click="$router.go(-1)">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        meldingvoor: "melding gemaakt door persoon zelf",
        status: "in behandeling"
      }
    };
  },
  computed: {
    settings() {
      return this.$store.getters["auth/settings"];
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    dossier() {
      return this.$store.getters["dossiers/dossier"];
    },
    gemeenten() {
      if (this.settings.gemeenten) {
        return this.settings["gemeenten"];
      }
      return [];
    },
    filteredGemeentes() {
      if (!this.gemeenten.length) {
        return this.gemeenten;
      }

      if (!this.form.gemeente) {
        return this.gemeenten;
      } else {
        return this.gemeenten.filter(option => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.form.gemeente.toLowerCase()) >= 0
          );
        });
      }
    }
  },
  methods: {
    submit() {
      if (this.$router.currentRoute.name === "dossier-aanpassen") {
        this.dossierAanpassen();
      } else {
        this.dossierAanmaken();
      }
    },
    dossierAanpassen() {
      this.$store
        .dispatch("dossiers/dossierAanpassen", this.form)
        .then(response => {
          this.$router.push({
            name: "dossier",
            params: { dossier: response.data.slug }
          });
        })
        .catch(error => {
          const element = document.getElementById(
            Object.keys(error.response.data.errors)[0]
          );
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        });
    },
    dossierAanmaken() {
      this.$store
        .dispatch("dossiers/dossierAanmaken", this.form)
        .then(response => {
          this.$router.push({
            name: "dossier",
            params: { dossier: response.data.slug }
          });
        })
        .catch(error => {
          const element = document.getElementById(
            Object.keys(error.response.data.errors)[0]
          );
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        });
    }
  },
  mounted() {
    if (this.$router.currentRoute.name === "dossier-aanpassen") {
      if (!this.dossier.slug) {
        this.$store
          .dispatch("dossiers/getDossier", this.$route.params.dossier)
          .then(() => {
            this.form = this.dossier;
          });
      } else {
        this.form = this.dossier;
      }
    }
  }
};
</script>
