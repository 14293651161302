import Vue from "vue";
import VueRouter from "vue-router";
import App from "../views/App.vue";
import Home from "@/App.vue";
import Login from "../views/Auth/Login.vue";
import FourZeroFour from "../views/FourZeroFour.vue";

import Passwordreset from "../views/Auth/Passwordreset.vue";
import PasswordChange from "../views/Auth/PasswordChange.vue";
import UserSettings from "@/views/Auth/UserSettings.vue";
import UserDevices from "@/views/Auth/UserDevices.vue";
import UserChangePicture from "@/views/Auth/UserChangePicture.vue";

import Dashboard from "../views/Dashboard/Dashboard.vue";

import GebruikersBase from "../views/Gebruikers/GebruikersBase.vue";
import GebruikersIndex from "../views/Gebruikers/GebruikersIndex.vue";
import GebruikerToevoegen from "../views/Gebruikers/GebruikerToevoegen.vue";
import GebruikerAanpassen from "../views/Gebruikers/GebruikerAanpassen.vue";

import RapportagesBase from "@/views/Rapportages/RapportagesBase.vue";
import RapportagesIndex from "@/views/Rapportages/RapportagesIndex.vue";

import DossiersBase from "@/views/Dossiers/DossiersBase.vue";
import DossiersIndex from "@/views/Dossiers/DossiersIndex.vue";
import DossierEdit from "@/views/Dossiers/DossierEdit.vue";
import DossierFotoEdit from "@/views/Dossiers/DossierFotoEdit.vue";
import Dossier from "@/views/Dossiers/Dossier.vue";

import TicketsBase from "@/views/Tickets/TicketsBase.vue";
import TicketCreate from "@/views/Tickets/TicketCreate.vue";
import TicketsIndex from "@/views/Tickets/TicketsIndex.vue";
import Ticket from "@/views/Tickets/Ticket.vue";

import EddiBase from "@/views/Eddis/EddiBase.vue";
import EddiIndex from "@/views/Eddis/EddiIndex.vue";
import EddiEdit from "@/views/Eddis/EddiEdit.vue";
import Eddi from "@/views/Eddis/Eddi.vue";
import EddiFotoEdit from "@/views/Eddis/EddiFotoEdit.vue";

import LogboekBase from "@/views/Logboek/LogboekBase.vue";
import LogboekIndex from "@/views/Logboek/LogboekIndex.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    children: [
      {
        path: "",
        name: "inloggen",
        component: Login,
      },
      {
        path: "wachtwoord-resetten",
        name: "wachtwoord-resetten",
        component: Passwordreset,
      },
      {
        path: "wachtwoord-resetten/:email/:token",
        name: "wachtwoord-veranderen",
        component: PasswordChange,
      },
      {
        path: "app",
        component: App,
        children: [
          {
            path: "",
            name: "app",
            component: Dashboard,
          },
          {
            path: "dossiers",
            component: DossiersBase,
            children: [
              {
                path: "",
                component: DossiersIndex,
                name: "dossiers-index",
              },

              {
                path: "aanmaken",
                component: DossierEdit,
                name: "dossier-aanmaken",
              },
              {
                path: "aanpassen/:dossier",
                component: DossierEdit,
                name: "dossier-aanpassen",
              },
              {
                path: "dossier-foto-aanpassen/:dossier",
                component: DossierFotoEdit,
                name: "dossier-foto-aanpassen",
              },
              {
                path: ":dossier",
                component: Dossier,
                name: "dossier",
              },
            ],
          },
          {
            path: "tickets",
            component: TicketsBase,
            children: [
              {
                path: "",
                component: TicketsIndex,
                name: "tickets-index",
              },

              {
                path: "aanmaken",
                component: TicketCreate,
                name: "ticket-aanmaken",
              },
              {
                path: ":ticket",
                component: Ticket,
                name: "ticket",
              },

              // {
              //   path: "aanmaken",
              //   component: DossierEdit,
              //   name: "dossier-aanmaken"
              // },
              // {
              //   path: "aanpassen/:dossier",
              //   component: DossierEdit,
              //   name: "dossier-aanpassen"
              // },
              // {
              //   path: "foto-aanpassen/:dossier",
              //   component: DossierFotoEdit,
              //   name: "dossier-foto-aanpassen"
              // },
              // {
              //   path: ":dossier",
              //   component: Dossier,
              //   name: "dossier"
              // }
            ],
          },
          {
            path: "coach",
            component: EddiBase,
            children: [
              {
                path: "",
                component: EddiIndex,
                name: "eddi-index",
              },
              {
                path: "toevoegen",
                component: EddiEdit,
                name: "eddi-toevoegen",
              },
              {
                path: "aanpassen/:eddi",
                component: EddiEdit,
                name: "eddi-aanpassen",
              },
              {
                path: ":eddi",
                component: Eddi,
                name: "eddi",
              },
              {
                path: "coach-foto-aanpassen/:eddi",
                component: EddiFotoEdit,
                name: "eddi-foto-aanpassen",
              },
            ],
          },
          {
            path: "instellingen",
            name: "instellingen",
            component: UserSettings,
          },
          {
            path: "instellingen-apparaten",
            name: "instellingen-apparaten",
            component: UserDevices,
          },
          {
            path: "instellingen-foto-aanpassen",
            name: "instellingen-foto-aanpassen",
            component: UserChangePicture,
          },
          {
            path: "rapportages",
            component: RapportagesBase,
            children: [
              {
                path: "",
                component: RapportagesIndex,
                name: "rapportages-index",
              },
            ],
          },
          {
            path: "logboek",
            component: LogboekBase,
            children: [
              {
                path: "",
                component: LogboekIndex,
                name: "logboek-index",
              },
            ],
          },
          {
            path: "gebruikers",
            component: GebruikersBase,
            children: [
              {
                path: "",
                component: GebruikersIndex,
                name: "gebruikers-index",
              },
              {
                path: "toevoegen",
                component: GebruikerToevoegen,
                name: "gebruiker-toevoegen",
              },
              {
                path: ":gebruiker/aanpassen",
                component: GebruikerAanpassen,
                name: "gebruiker-aanpassen",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "*",
    component: FourZeroFour,
    name: "404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "is-active",
  linkExactActiveClass: "is-active",
  routes,
});

export default router;
