<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}
</style>

<template>
  <div>
    <div class="users-title">Gebruikers</div>
    <div class="block table-icons">
      <b-tooltip label="Gebruiker toevoegen" position="is-top">
        <span class="pointer table-icon" @click="gebruikerToevoegen()">
          <b-icon icon="plus"></b-icon>
        </span>
      </b-tooltip>
      <b-tooltip
        v-if="selected"
        label="Uitnodiging opnieuw sturen"
        position="is-top"
      >
        <span class="pointer" @click="uitnodigingOpnieuwSturen()">
          <b-icon class="pointer" icon="envelope" />
        </span>
      </b-tooltip>
      <b-tooltip v-if="selected" label="Gebruiker aanpassen" position="is-top">
        <span class="pointer table-icon" @click="gebruikerAanpassen()">
          <b-icon icon="edit"></b-icon>
        </span>
      </b-tooltip>
      <span v-if="selected">
        <b-tooltip
          label="Gebruiker activeren"
          v-if="selected.status !== 'actief'"
          position="is-top"
        >
          <span class="pointer table-icon" @click="gebruikerActiveren()">
            <b-icon icon="user"></b-icon>
          </span>
        </b-tooltip>
      </span>
      <span v-if="selected">
        <b-tooltip
          label="Gebruiker deactiveren"
          v-if="selected.status === 'actief'"
          position="is-top"
        >
          <span class="pointer table-icon" @click="gebruikerDeactiveren()">
            <b-icon icon="user-slash"></b-icon>
          </span>
        </b-tooltip>
      </span>
      <b-tooltip
        label="Gebruiker archiveren"
        v-if="selected && selected['status'] !== 'gearchiveerd'"
        position="is-top"
      >
        <span class="pointer table-icon" @click="gebruikerArchiveren()">
          <b-icon icon="archive"></b-icon>
        </span>
      </b-tooltip>
    </div>

    <br />
    <br />
    <div>
      <b-tabs>
        <b-loading v-model="ajaxloading.gettingGebruikers" />
        <b-tab-item label="Actieve gebruikers">
          <b-table
            :data="huidigeGebruikers"
            :bordered="true"
            :striped="true"
            :hoverable="true"
            :loading="false"
            :focusable="false"
            :mobile-cards="false"
            :selected.sync="selected"
            @dblclick="gebruikerAanpassen()"
          >
            <b-table-column
              field="id"
              label="ID"
              width="40"
              numeric
              v-slot="props"
            >
              {{ props.row.id }}
            </b-table-column>

            <b-table-column field="name" label="Naam" v-slot="props">
              {{ props.row.name }}
            </b-table-column>

            <b-table-column field="email" label="Email" v-slot="props">
              {{ props.row.email }}
            </b-table-column>

            <b-table-column label="Ticket Notificaties" v-slot="props">
              <span
                v-if="
                  props.row.settings && props.row.settings.ticket_notificaties
                "
                >Ja</span
              >

              <span v-else>Nee</span>
            </b-table-column>

            <b-table-column field="rol" label="Rol" v-slot="props">
              {{ props.row.rol }}
            </b-table-column>

            <b-table-column
              field="Laatste inlog"
              label="Laatste inlog"
              v-slot="props"
            >
              <span v-if="props.row.last_login">{{
                formatDatePickerDateAndTime(props.row.last_login)
              }}</span>
              <span v-else>Nog niet ingelogd.</span>
            </b-table-column>

            <b-table-column field="status" label="Status" v-slot="props">
              {{ props.row.status }}
            </b-table-column>
          </b-table>
        </b-tab-item>
        <b-tab-item label="Archiveerde gebruikers">
          <b-table
            :data="gearchiveerdeGebruikers"
            :bordered="true"
            :striped="true"
            :hoverable="true"
            :loading="false"
            :focusable="false"
            :mobile-cards="false"
            :selected.sync="selected"
            @dblclick="gebruikerAanpassen()"
          >
            <b-table-column
              field="id"
              label="ID"
              width="40"
              numeric
              v-slot="props"
            >
              {{ props.row.id }}
            </b-table-column>

            <b-table-column field="name" label="Naam" v-slot="props">
              {{ props.row.name }}
            </b-table-column>

            <b-table-column field="email" label="Email" v-slot="props">
              {{ props.row.email }}
            </b-table-column>

            <b-table-column label="Ticket Notificaties" v-slot="props">
              <span
                v-if="
                  props.row.settings && props.row.settings.ticket_notificaties
                "
                >Ja</span
              >

              <span v-else>Nee</span>
            </b-table-column>

            <b-table-column field="rol" label="Rol" v-slot="props">
              {{ props.row.rol }}
            </b-table-column>

            <b-table-column
              field="Laatste inlog"
              label="Laatste inlog"
              v-slot="props"
            >
              <span v-if="props.row.last_login">{{
                formatDatePickerDateAndTime(props.row.last_login)
              }}</span>
              <span v-else>Nog niet ingelogd.</span>
            </b-table-column>

            <b-table-column field="status" label="Status" v-slot="props">
              {{ props.row.status }}
            </b-table-column>
          </b-table>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      selected: null
    };
  },
  computed: {
    gebruikers() {
      return this.$store.getters["gebruikers/gebruikers"];
    },
    huidigeGebruikers() {
      return this.gebruikers.filter(a => a.status !== "gearchiveerd");
    },
    gearchiveerdeGebruikers() {
      return this.gebruikers.filter(a => a.status === "gearchiveerd");
    }
  },
  methods: {
    gebruikerToevoegen() {
      this.$router.push({ name: "gebruiker-toevoegen" });
    },
    gebruikerAanpassen() {
      this.$router.push({
        name: "gebruiker-aanpassen",
        params: { gebruiker: this.selected.id }
      });
    },
    gebruikerActiveren() {
      this.$buefy.dialog.confirm({
        title: "Gebruiker activeren",
        message:
          'Weet je zeker dat je gebruiker "' +
          this.selected.name +
          '" wil activeren?',
        confirmText: "Activeren",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("gebruikers/gebruikerActiveren", this.selected)
            .then(() => {
              this.selected = null;
            })
      });
    },
    gebruikerDeactiveren() {
      this.$buefy.dialog.confirm({
        title: "Gebruiker deactiveren",
        message:
          'Weet je zeker dat je gebruiker "' +
          this.selected.name +
          '" wil deactiveren?',
        confirmText: "Deactiveren",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("gebruikers/gebruikerDeactiveren", this.selected)
            .then(() => {
              this.selected = null;
            })
      });
    },
    uitnodigingOpnieuwSturen() {
      const data = {};
      data.url = "gebruiker-uitnodiging-opnieuw-sturen/" + this.selected.id;
      data.method = "POST";
      data.loading = "eddiedit";

      this.$buefy.dialog.confirm({
        title: "Uitnodiging sturen",
        message:
          "Wilt u de uitnodiging voor " +
          this.selected.name +
          " opnieuw versturen?",
        type: "is-info",
        hasIcon: true,
        onConfirm: () => this.$store.dispatch("axiosWeb", data)
      });
    },
    gebruikerArchiveren() {
      this.$buefy.dialog.confirm({
        title: "Gebruiker archiveren",
        message:
          'Weet je zeker dat je gebruiker "' +
          this.selected.name +
          '" wil archiveren?',
        confirmText: "Archiveren",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("gebruikers/gebruikerArchiveren", this.selected)
            .then(() => {
              this.selected = null;
            })
      });
    }
  },
  mounted() {}
};
</script>
