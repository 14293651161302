import router from "../../router";

export const dossierAanmaken = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "dossier-aanmaken";
  data.method = "POST";
  data.loading = "dossieraanmaken";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setNewDossier", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const dossierAanpassen = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "dossier-aanpassen/" + payload.slug;
  data.method = "POST";
  data.loading = "dossieraanmaken";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const editDossierPicture = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "dossier-foto-aanpassen/" + payload.get("slug");
  data.method = "POST";
  data.loading = "dossierfotoaanpassen";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setDossier", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const searchDossiers = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "search-dossiers";
  data.method = "POST";
  data.loading = "searchingdossiers";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const getDossiersInbehandeling = function(
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url = "get-dossiers-inbehandeling";
  data.method = "GET";
  data.loading = "gettingDossiers";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setDossiers", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const getDossiers = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-dossiers";
  data.method = "GET";
  data.loading = "gettingDossiers";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setDossiers", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const getDossier = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-dossier/" + payload;
  data.method = "GET";
  data.loading = "gettingdossier";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setDossier", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const createRapportage = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "create-dossier-rapportage/" + router.currentRoute.params.dossier;
  data.method = "POST";
  data.loading = "createRapportage";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setNewRapportage", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const createComment = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "create-dossier-rapportage-comment/" + payload.selectedRapportage;
  data.method = "POST";
  data.loading = "createComment";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("updateRapportage", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const getLogs = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-dossier-logs/" + payload;
  data.method = "GET";
  data.loading = "getdosieractionlogs";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setDossierLogs", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const deleteDossier = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "dossier-verwijderen/" + payload.slug;
  data.method = "POST";
  data.loading = "eddiedit";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("deleteDossier", payload);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const deleteDossierPicture = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "delete-dossier-picture/" + payload.slug;
  data.method = "POST";
  data.loading = "deletePicture";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};
