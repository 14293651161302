export const setEddis = function(state, payload) {
  state.eddis = payload;
};

export const setEddi = function(state, payload) {
  state.eddi = payload;
};

export const setUpdatedEddi = function(state, payload) {
  const array = state.eddis;
  const id = payload.id;
  const index = array.findIndex(a => a.id === id);
  array.splice(index, 1, payload);
};
