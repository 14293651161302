import vue from "vue";

export const setGebruikers = function(state, payload) {
  state.gebruikers = payload;
};

export const setNewUser = function(state, payload) {
  state.gebruikers.push(payload);
};

export const changeUserStatus = function(state, payload) {
  const array = state.gebruikers;
  const id = payload.id;
  const index = array.findIndex(a => a.id === id);
  array.splice(index, 1, payload);
};

export const setGebruiker = function(state, payload) {
  state.gebruiker = payload;
};
