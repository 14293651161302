import Vue from "vue";
import { mapGetters } from "vuex";

const validation = Vue.mixin({
  computed: {
    ...mapGetters({
      validation: "valerrors"
    })
  }
});

export default validation;
