import validation from "./validation.js";
import loading from "./loading.js";
import customMounted from "./customMounted.js";
import hoofdletter from "./hoofdletter.js";
import formatDatabaseDate from "./formatDatabaseDate.js";
import getMaxRow from "./getMaxRow.js";

export default {
  validation: validation,
  loading: loading,
  customMounted: customMounted,
  hoofdletter: hoofdletter,
  formatDatabaseDate: formatDatabaseDate,
  getMaxRow: getMaxRow
};
