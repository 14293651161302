<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}
</style>

<template>
  <div>
    <div class="box container">
      <div class="users-title">Rapportages</div>

      <br />
      <br />
      <div>
        <form action="" @submit.prevent="rapportageAanmaken()">
          <b-field id="datum_incident" label="Van">
            <b-datepicker
              size="is-small"
              v-model="form.begindatum"
              locale="nl-NL"
              rounded
              placeholder="Selecteer begindatum van rapportage"
              icon="calendar"
              :first-day-of-week="1"
            >
            </b-datepicker>
          </b-field>
          <b-field id="datum_incident" label="Tot">
            <b-datepicker
              size="is-small"
              v-model="form.einddatum"
              locale="nl-NL"
              rounded
              placeholder="Selecteer einddatum van rapportage"
              icon="calendar"
              :first-day-of-week="1"
            >
            </b-datepicker>
          </b-field>

          <br />

          <b-button
            v-if="user.rol === 'admin' || user.rol === 'vizier'"
            native-type="submit"
            :loading="ajaxloading.rapportageaanmaken"
            type="is-primary is-rounded"
            >Rapportage maken</b-button
          >
          <span v-else>Gebruiker is niet gemachtigd voor rapportages</span>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {},
      gemeenten: []
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    filteredGemeentes() {
      if (!this.gemeenten.length) {
        return this.gemeenten;
      }
      if (!this.form.gemeente) {
        return this.gemeenten;
      } else {
        return this.gemeenten.filter(option => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.form.gemeente.toLowerCase()) >= 0
          );
        });
      }
    }
  },
  methods: {
    rapportageAanmaken() {
      this.$store.dispatch("rapportages/aanmaken", this.form);
    },
    checkAuthorizedGemeenten() {
      if (this.user.rapportages_arnhem || this.user.rol === "superuser") {
        this.gemeenten.push("Arnhem");
      }
      if (this.user.rapportages_enschede || this.user.rol === "superuser") {
        this.gemeenten.push("Enschede");
      }
      if (
        this.user.rapportages_overige_gemeente ||
        this.user.rol === "superuser"
      ) {
        this.gemeenten.push("Overige gemeente");
      }
    }
  },
  created() {
    this.form.begindatum = new Date(new Date().getFullYear(), 0, 1);
    this.form.einddatum = new Date(new Date().getFullYear(), 11, 31);
  },
  watch: {
    user: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler() {
        this.checkAuthorizedGemeenten();
      }
    }
  },
  mounted() {
    // this.customMounted("logboek/getUsers");
    this.checkAuthorizedGemeenten();
  }
};
</script>
