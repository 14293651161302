var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"box container"},[_c('div',{staticClass:"users-title"},[_vm._v("Gebruiker "+_vm._s(_vm.gebruiker.name)+" aanpassen")]),_c('br'),_c('br'),_c('br'),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.gebruikerAanpassen()}}},[_c('b-field',{attrs:{"label":"Voornaam","type":{ 'is-danger': _vm.validation.voornaam },"message":{
            [_vm.validation.voornaam]: _vm.validation.voornaam
          }}},[_c('b-input',{attrs:{"rounded":"","placeholder":"Voornaam"},model:{value:(_vm.form.voornaam),callback:function ($$v) {_vm.$set(_vm.form, "voornaam", $$v)},expression:"form.voornaam"}})],1),_c('b-field',{attrs:{"label":"Achternaam","type":{ 'is-danger': _vm.validation.achternaam },"message":{
            [_vm.validation.achternaam]: _vm.validation.achternaam
          }}},[_c('b-input',{attrs:{"rounded":"","placeholder":"Achternaam"},model:{value:(_vm.form.achternaam),callback:function ($$v) {_vm.$set(_vm.form, "achternaam", $$v)},expression:"form.achternaam"}})],1),_c('b-field',{attrs:{"label":"Email","type":{ 'is-danger': _vm.validation.email },"message":{
            [_vm.validation.email]: _vm.validation.email
          }}},[_c('b-input',{attrs:{"rounded":"","placeholder":"Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('b-field',{attrs:{"label":"Telefoonnummer","type":{ 'is-danger': _vm.validation.telefoonnummer },"message":{
            [_vm.validation.telefoonnummer]: _vm.validation.telefoonnummer
          }}},[_c('b-input',{attrs:{"rounded":"","placeholder":"Telefoonnummer"},model:{value:(_vm.form.telefoonnummer),callback:function ($$v) {_vm.$set(_vm.form, "telefoonnummer", $$v)},expression:"form.telefoonnummer"}})],1),_c('div',{staticClass:"field"},[_c('b-checkbox',{model:{value:(_vm.form.password_change),callback:function ($$v) {_vm.$set(_vm.form, "password_change", $$v)},expression:"form.password_change"}},[_vm._v(" Wachtwoord veranderen ")])],1),(_vm.form.password_change)?_c('span',[_c('b-field',{attrs:{"label":"Huidig password","type":{ 'is-danger': _vm.validation.huidig_password },"message":{
              [_vm.validation.huidig_password]: _vm.validation.huidig_password
            }}},[_c('b-input',{attrs:{"type":"password","rounded":"","placeholder":"Huidig password","password-reveal":""},model:{value:(_vm.form.huidig_password),callback:function ($$v) {_vm.$set(_vm.form, "huidig_password", $$v)},expression:"form.huidig_password"}})],1),_c('b-field',{attrs:{"label":"Nieuw password","type":{ 'is-danger': _vm.validation.nieuw_password },"message":{
              [_vm.validation.nieuw_password]: _vm.validation.nieuw_password
            }}},[_c('b-input',{attrs:{"type":"password","rounded":"","placeholder":"Nieuw password","password-reveal":""},model:{value:(_vm.form.nieuw_password),callback:function ($$v) {_vm.$set(_vm.form, "nieuw_password", $$v)},expression:"form.nieuw_password"}})],1),_c('b-field',{attrs:{"label":"Nieuw password herhalen","type":{ 'is-danger': _vm.validation.nieuw_password_confirmation },"message":{
              [_vm.validation.nieuw_password_confirmation]:
                _vm.validation.nieuw_password_confirmation
            }}},[_c('b-input',{attrs:{"type":"password","rounded":"","placeholder":"Nieuw password herhalen","password-reveal":""},model:{value:(_vm.form.nieuw_password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "nieuw_password_confirmation", $$v)},expression:"form.nieuw_password_confirmation"}})],1)],1):_vm._e(),_c('b-button',{attrs:{"native-type":"submit","loading":_vm.ajaxloading.gebruikerAanpassen,"type":"is-primary is-rounded"}},[_vm._v("Gebruiker aanpassen")]),_vm._v("  "),_c('b-button',{attrs:{"type":"is-rounded"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Annuleren")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }