<template>
  <div class="FlashBannerOuterDiv">
    <transition name="slide">
      <nav :class="['navbar flashBanner', customClass]" v-if="flashBanner">
        <div class="flashBannerText">
          {{ flashBanner }}&nbsp;
          <a class="flashBannerIcon" @click="destroyFlashBanner()"
            ><icon icon="times"></icon
          ></a>
        </div>
      </nav>
    </transition>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {
    flashBanner() {
      return this.$store.getters["flashBanner"];
    },
    customClass() {
      if (
        this.flashBanner === "Deze actie is niet toegestaan." ||
        this.flashBanner ===
          "U bent niet ingelogd. Log in om verder te gaan." ||
        this.flashBanner === "Onjuiste code, probeer het opnieuw" ||
        this.flashBanner ===
          "Uw account is vergrendeld. Neem contact op met uw systeembeheerder." ||
        this.flashBanner === "Inlogpoging verlopen. Probeer het opnieuw." ||
        this.flashBanner ===
          "Laatste email is minder dan 2 minuten geleden verzonden. Controleer uw inbox"
      ) {
        return "is-danger";
      }

      if (
        this.flashBanner ===
          "Uw wachtwoord-reset link is ongeldig of verlopen." ||
        this.flashBanner ===
          "De applicatie wordt momenteel ge-update. Probeer het later opnieuw."
      ) {
        return "is-warning";
      }

      return "is-success";
    }
  },
  methods: {
    destroyFlashBanner() {
      this.$store.dispatch("destroyFlashBanner");
    }
  },
  mounted() {}
};
</script>
