<template>
  <div>
    <b-field :label="computedTitle">
      <span v-if="!preview">
        <b-field class="file">
          <b-upload v-model="file" expanded>
            <a :class="['button is-rounded is-primary is-fullwidth ' + size]">
              <b-icon icon="upload"></b-icon>
              <span>{{ computedPictureName || "Kies foto" }}</span>
            </a>
          </b-upload>
        </b-field>
        <p class="help is-danger" v-if="validation[modelKey]">
          {{ validation[modelKey][0] }}
        </p>
      </span>
      <span v-else>
        <div class="box has-text-centered" v-if="previewImage">
          <button
            class="delete is-small is-pulled-right"
            type="button"
            @click="deletePicture()"
          ></button>

          <br />

          <!-- <img
            v-if="previewImage.url"
            :style="'height:' + height + 'px;'"
            :src="previewImage.url"
          /> -->

          <cropper
            v-if="previewImage.url"
            class="cropper"
            :style="'height:' + height + 'px;'"
            :src="previewImage.url"
            :resizeImage="{ wheel: false }"
            :stencil-props="{
              aspectRatio: 1 / 1
            }"
            @change="change"
          ></cropper>

          <br />
        </div>

        <div
          class="box has-text-centered"
          v-if="!previewImage && existingPicture"
        >
          <button
            class="delete is-small is-pulled-right"
            type="button"
            @click="deletePicture()"
          ></button>

          <br />

          <img
            :style="'height:' + height + 'px;'"
            :src="linkToExistingPictureBase + '/' + existingPicture"
          />

          <br />
        </div>

        <b-field>
          <b-upload v-model="file" expanded @input="fileChanged()">
            <a :class="['button is-rounded is-primary is-fullwidth ' + size]">
              <b-icon icon="upload"></b-icon>
              <span>{{ computedPictureName || "Kies foto" }}</span>
            </a>
          </b-upload>
        </b-field>
        <p class="help is-danger" v-if="validation[modelKey]">
          {{ validation[modelKey][0] }}
        </p>
      </span>
    </b-field>
  </div>
</template>

<script>
import Vue from "vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  components: {
    Cropper
  },
  props: {
    preview: {
      default: false,
      type: Boolean
    },
    title: {
      type: String
    },
    model: {
      required: true,
      type: FormData
    },
    modelKey: {
      required: true,
      type: String
    },
    placeholder: {
      type: String
    },
    size: {
      default: "is-normal",
      type: String
    },
    height: {
      default: "400",
      type: String
    },
    linkToExistingPictureBase: {
      type: String,
      default: null
    },
    existingPicture: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      file: {},
      previewImage: null
    };
  },
  computed: {
    computedTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
    computedPictureName() {
      if (this.file) {
        return this.file.name;
      } else {
        return "";
      }
    }
  },
  methods: {
    fileChanged() {
      this.clearValidationError();
      this.model.append(this.modelKey, this.file, this.file.name);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          let filepreview = {
            name: this.file.name,
            url: reader.result
          };
          this.previewImage = filepreview;
        },
        false
      );
      reader.readAsDataURL(this.file);
    },
    deletePicture() {
      this.previewImage = null;
      this.model.delete(this.modelKey);
      this.file = null;
    },
    clearValidationError() {
      this.$store.dispatch("clearValidationError", this.modelKey);
    },
    change({ coordinates, canvas }) {
      this.model.append(this.modelKey + "_width", coordinates.width);
      this.model.append(this.modelKey + "_height", coordinates.height);
      this.model.append(this.modelKey + "_x", coordinates.left);
      this.model.append(this.modelKey + "_y", coordinates.top);
    }
  },
  mounted() {
    this.file = this.model[this.modelKey];
  }
};
</script>
