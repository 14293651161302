<style scoped>
.ticket-container {
  white-space: nowrap;
  overflow: hidden !important;
}

.ticket {
  text-overflow: ellipsis !important;
  /* white-space: nowrap; */
  overflow: hidden !important;
}

.column {
  width: inherit;
}
</style>

<template>
  <div class="">
    <b-loading v-model="ajaxloading.getEddiDashboard" />
    <div class="box">
      <div class="columns">
        <div class="column">
          <div class="title is-5">Openstaande dossiers:</div>
          <div class="content">
            <span v-if="eddiDashboard.open_dossiers.length">
              <li
                v-for="dossier in eddiDashboard.open_dossiers"
                :key="dossier.id"
              >
                <a
                  @click="
                    $router.push({
                      name: 'dossier',
                      params: { dossier: dossier.slug }
                    })
                  "
                >
                  {{ dossier.slug }}</a
                >
              </li>
            </span>
            <span v-else>Geen openstaande dossiers</span>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column content ticket-container">
          <div class="title is-5">Beantwoorde tickets:</div>
          <span v-if="eddiDashboard.beantwoorde_tickets.length">
            <a
              v-for="ticket in eddiDashboard.beantwoorde_tickets"
              :key="ticket.id"
              @click="
                $router.push({
                  name: 'ticket',
                  params: { ticket: ticket.id }
                })
              "
            >
              <li v-html="stripHTML(ticket.ticket)" class="ticket"></li
            ></a>
          </span>
          <span v-else>Geen openstaande tickets</span>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="columns">
        <div class="column content ticket-container">
          <div class="title is-5">Openstaande tickets:</div>
          <span v-if="eddiDashboard.open_tickets.length">
            <a
              v-for="ticket in eddiDashboard.open_tickets"
              :key="ticket.id"
              @click="
                $router.push({
                  name: 'ticket',
                  params: { ticket: ticket.id }
                })
              "
            >
              <li v-html="stripHTML(ticket.ticket)" class="ticket"></li
            ></a>
          </span>
          <span v-else>Geen openstaande tickets</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {
    eddiDashboard() {
      return this.$store.getters["eddiDashboard"];
    }
  },
  methods: {
    stripHTML(payload) {
      if (payload) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = payload;
        return tmp.textContent || tmp.innerText || "";
      }
    }
  },
  mounted() {
    this.$store.dispatch("getEddiDashboard");
  }
};
</script>
